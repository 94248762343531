.autoResponsePopupcontainer {
    position: absolute !important;
    background-color: white !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    outline: none !important;
    top: 50% !important;
    border-radius: 5px !important;
    width: 60% !important;
    height: 620px !important;
    padding-left: 0px !important;
    padding-right: 10px !important;


    .CommonLoadermaingrid {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 45px;

    }

    .MuiAccordion-root {
        margin: 0 !important;
        box-shadow: none;
    }

    ::before {
        display: none !important;
    }

    .GridIcon {
        text-align: right;
        cursor: pointer;
        padding-top: 2%;
        margin-top: 1%;
    }

    .accordian {
        margin-bottom: 5% !important;
    }

    .accordianDetails {
        background: white !important;
        padding: 0;
    }

    .MuiAccordionDetails-content {
        padding: 0 !important;
        box-shadow: none !important;
    }

    .MuiAccordionGroup-root {
        border: 0px !important;
    }

    .accordianSummary {
        background-color: #004791 !important;
        color: white !important;
        border-radius: 0 10px 10px 0 !important;
        /* padding: 20px 30px!important; */
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        min-height: 50px !important;
        opacity: 1 !important;
    }

    .Mui-disabled .accordianSummary {
        background-color: #004791 !important;
        color: white !important;
        border-radius: 0 10px 10px 0 !important;
        /* padding: 20px 30px!important; */
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        min-height: 50px !important;
        opacity: 1 !important;
    }

    .MuiAccordionSummary-content {
        margin: 10px 0 !important;
    }

    .questions {
        background-color: white;
        color: black;
        border-radius: 0 10px 10px 0 !important;
        padding: 5% 10% !important;
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        border: 1px solid #ececec;
        width: 100%;
    }

    .surveyBtn {
        background-color: white;
        color: #005BB9;
        border-radius: 0 10px 10px 0 !important;
        padding: 5% 10% !important;
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        border: 1px solid #005BB9;
        width: 100%;
    }

    button.questions.active {
        background-color: #A1CFFF !important;
    }

    button.surveyBtn.active {
        background-color: #A1CFFF !important;
        color: black;
        border: none;
    }

    .MuiAccordion-root {
        border-bottom: none !important;
    }

    .MuiAccordionSummary-root {
        padding-top: 0 !important;
        padding-left: 10% !important;
    }

    .MuiGrid-root {
        padding-top: 0px;
        padding-right: 5px;
    }

    .label_text {
        height: 16px;
        font-weight: 600;
    }

    .label_text_email{
        font-size: 18px;
        height: 16px;
        font-weight: 600;
    }

    .sub_label_text {
        margin-top: 2% ;
        height: 16px;
        font-weight: 600;
        font-size: 14px;
    }

    .body_text_label {
        height: 16px;
        font-weight: 600;
        margin-top: 2%;
        font-size: 14px;
    }

    input {
        font-size: 14px !important;
        padding: 0px !important;

    }

    .MuiSelect-select {
        font-size: 14px !important;
        padding: 0px !important;
    }

    textarea {
        font-size: 14px !important;
        /* padding: 5px 5px!important; */
    }

    .questionBox {
        border: 1px solid #d3d3d3;
        padding: 30px;
        border-radius: 4px;
        margin-bottom: 2%;
        position: relative;
    }

    .answerBox {
        border: 1px solid #d3d3d3;
        padding: 0 10px;
        border-radius: 4px;
        margin-top: 2%;
    }

    .richTextEditor {
        border: 1px solid #d3d3d3;
        padding: 0 10px;
        border-radius: 4px;
        margin-top: 1%;
    }

    .iconTickClose {
        width: 10px;
        height: 10px;
    }

    .tickBox,
    .closeBox {
        border: 1px solid #d3d3d3;
        padding: 2px 12px;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 999;
        background-color: white;
        align-items: center;
    }

    .tickBox:hover,
    .closeBox:hover {
        cursor: pointer;
    }

    .closeBox {
        margin-left: 10%;
    }

    .scrollable-box {
        height: 300px;
        overflow-y: auto;
    }

    .scrollable-accordian {
        height: 190px;
        overflow-y: auto;
        display: block;
    }

    .nextBtn {
        width: 100%;
        color: white !important;
        margin-top: 5% !important;
    }

    .Mui-disabled {
        font-size: 14px !important;
    }

    .scrollbar-space {
        padding-right: 2%;
        padding-left: 8% !important;
    }

    .surveyDiv {
        padding-right: 2%;
        padding-left: 8% !important;
        margin-top: 2%;
    }

    .surveyQuestionDiv {
        /* max-height: 450px;
        overflow-y: scroll; */
        padding-right: 2%;
        padding-left: 6% !important;
		height:500px;
    }

    .surveyDiv,
    .surveyQuestionDiv {
        align-items: center;
    }

    .surveyText {
        align-items: center;
        margin: 0;
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        padding: 10px;
        cursor: pointer;
        transition: opacity 0.3s ease;
    }

    .arrowLeft {
        left: 2%;
        transform: rotate(90deg);
    }

    .arrowRight {
        right: -3%;
        transform: rotate(-90deg);
    }

    .accordianIcon {
        transform: rotate(180deg);
    }

    .descDiv {
        margin-top: 1%;
    }

    .smsDesc {
        margin: 0;
        font-size: 14px;
        color: #262626;
    }

    .Cancelbtn {
        font-family: var(--fontFamily) !important;
        font-size: var(--fontsizesmall);
        background-color: var(--secondary) !important;
        color: var(--Agent_textcolor2) !important;
        width: 180px !important;
        height: 35px !important;
        border-radius: 5px !important;
        text-transform: none !important;
        font-weight: bold !important;
        border: 1px solid var(--Agent_textcolor2);
    }

    .Savebtn {
        font-family: var(--fontFamily) !important;
        font-size: var(--fontsizesmall);
        background-color: var(--Agent_bgcolor1) !important;
        width: 180px !important;
        height: 35px !important;
        border-radius: 5px !important;
        font-size: 14px;
        text-transform: none !important;
        font-weight: bold !important;
        margin-left: 2%;
    }
	.disablesavebn{
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: #ffffff !important;
		color: #c5d3e1;
		width: 180px !important;
        height: 35px !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		pointer-events: none;
		cursor: not-allowed;
		margin-left: 2%;
	}

    .Mui-disabled .Savebtn {
        background-color: none;
    }

    .saveCancelDiv {
        display: flex;
        justify-content: flex-end;
        margin-top: 4%;

    }

    .rdw-option-wrapper {
        padding: 0 !important;
    }

    .textField {
        margin-top: 1%;
    }

    .errorMsg {
        color: red;
        font-size: 14px;
    }

    .rdw-editor-main {
        min-height: 150px;
        max-height: 150px;
        overflow-y: scroll;
    }

    .rdw-editor-toolbar {
        border: 1px solid #BCC5CC;
        margin-top: 1%;
    }

    .MuiInputBase-root{
        padding: 6px 10px!important;
    }

    /* Target webkit-based browsers (Chrome, Safari, newer versions of Opera) */
    ::-webkit-scrollbar {
        width: 6px;
        /* Width of the scrollbar */
    }

    /* Track (the area where the thumb rests) */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* Color of the track */
    }

    /* Thumb (the draggable scrolling element) */
    ::-webkit-scrollbar-thumb {
        background: #888;
        /* Color of the thumb */
        border-radius: 6px;
        /* Rounded corners of the thumb */
    }

    /* Optional: Hover state of the thumb */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }
}


@media only screen and (min-width: 0px) and (max-width: 1269px) {
    .autoResponsePopupcontainer {
        position: absolute !important;
        background-color: white !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        outline: none !important;
        top: 50% !important;
        border-radius: 5px !important;
        width: 85vw !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}