.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1000;
  }
  
  .modal-content {
	background: white;
	padding: 15px;
	border-radius: 4px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	max-width: 300px;
	width: 100%;
	text-align: center;
  }
  
  button {
	margin-top: 10px;
	padding: 8px 16px;
	background-color: #007bff;
	border: none;
	color: white;
	border-radius: 4px;
	cursor: pointer;
  }
  
  button:hover {
	background-color: #0056b3;
  }
  