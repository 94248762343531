.SurveyTemplate2container{
	.Survey_bodygrid_Template3{
		padding-right: 25vw !important;
		padding-left: 25vw !important;
		padding-top: 25px;

	}

	.Heading {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}
	.questiontext{
		color: var(--Secondary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left;
		line-height: 22px;
	}

	.Mui-checked {
		color: var(--brandcolor) !important /* Color when checked */
	  }
	

	.checkboxdiv{
		min-height: 70px !important;
		border-radius: 10px !important;
		text-transform: none !important;
		border: 1.5px solid #676767 !important;
		width: 100% !important;
		text-transform: none !important;
		font-weight: normal !important;
		margin-bottom: 10px;
		background-color: var(--Primary) !important;
		margin-left: 1px;

		.MuiFormControlLabel-label {
			text-align: left;
			font-weight: normal;
			color: var(--Secondary) !important;
			font-size: var(--fontsizesmall) !important;
			font-family: var(--fontFamily) !important;
		}

	}

	.checkboxdivselected{
		min-height: 70px !important;
		border-radius: 10px !important;
		text-transform: none !important;
		border: 1.5px solid #676767 !important;
		width: 100% !important;
		font-weight: bold !important;
		margin-bottom: 10px;
		background-color: var(--lightblue) !important;
		margin-left: 1px;

		.MuiFormControlLabel-label {
			text-align: left;
			font-weight: bold;
			color: var(--Secondary) !important;
			font-size: var(--fontsizesmall) !important;
			font-family: var(--fontFamily) !important;
		}

	}


	.Nextbtn{
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		height: 55px !important;
		width: 100% !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
	}

	.disablebtn{
		font-family: var(--fontFamily) !important;
		background-color: #989898 !important;
		width: 100% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		bottom: 10px !important;
		text-transform: none !important;
		color: #000000 !important;
		font-weight: bold !important;
		pointer-events: none;
		cursor: not-allowed;
	}

	.textarea{
		border-radius: 10px !important;
		border: 1.5px solid #676767 !important;
		width: 100% !important;
		height: 100px !important;
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		margin-bottom: 10px;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}

}

@media only screen and (min-width: 0px) and (max-width: 1269px) {
	.SurveyTemplate2container{
		.Survey_bodygrid_Template3 {
			padding-right: 8vw !important;
			padding-left: 8vw !important;
		}
	}
}