.reqCallbackParent{
	width: 100% !important;
	font-family: var(--fontFamily) !important;
	.requestCall{
		font-size: var(--fontsizemedium);
		width: 100% !important;
		background-color: var(--btncolor) !important;
		color: var(--secondary);
		height: 55px !important;
		border-radius: 10px !important;
		/* font-size: var(--fontsizemedium) !important; */
		text-transform: none !important;
		font-weight: bold !important;
	}
	.cancelCall{
		background-color: var(--secondary) !important;
		color: var(--primary) !important;
		min-height: 55px !important;
		width: 100% !important;
		border-radius: 10px !important;
		border: 1px solid var(--primary) !important;
		text-transform: none !important;
		font-weight: bold !important;
		font-size: var(--fontsizemedium) !important;
	}
}


.reqcallbackModal{
	position: absolute ;
	top:50% ;
	left:50% ;
	transform: translate(-60%, -50%) ;
	width: 500px;
	background:var(--secondary);
	border-radius:10px;
	padding:20px;

	.callbackConfirm{
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 98% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		font-weight: bold !important;

	}

	.callbackCancel{
		font-family: var(--fontFamily) !important;
		background-color: var(--secondary) !important;
		color: var(--primary) !important;
		height: 55px !important;
		width: 98% !important;
		border-radius: 10px !important;
		border: 1px solid var(--primary) !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		font-weight: bold !important;

	}

	@media (max-width:799px){
		width: 80% !important;
	}

	@media (max-width:1299px){
			left:50% !important;
			transform: translate(-50%, -50%) !important;
	}

}

