.datepickerForm fieldset.MuiOutlinedInput-notchedOutline,
.datepickerForm :hover fieldset.MuiOutlinedInput-notchedOutline,
.datepickerForm .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
	border-color: transparent;
}

.datepickerForm{
	background: var(--lightgrey);
	border-radius: 10px;
	font-weight: bold !important;;
}
