.profileViewContainer {
	font-family: var(--fontFamily) !important;
	font-size: var(--fontsizesmall);

	.viewSection {
		height: 88vh;
		padding: 0 !important;

		.MuiAppBar-root{
			background-color: var(--lightgrey);
			color: var(--primary);
		}
		.namelabel {
			color: var(--primary) !important;
			font-size: var(--fontsizesmall) !important;
			font-family: var(--fontFamily) !important;
		}
		.profileDetailsSection { padding: 2rem; }
		.cardAction { margin-top: 6vh; }
		.cardAction .MuiButton-root { background-color: var(--btncolor); color: var(--secondary); height: 55px; border-radius: 10px; font-size: var(--fontsizemedium); text-transform: none; }
		.cardAction .MuiButton-root:hover { background-color: var(--btncolor); }
	}

	.Donebtn{
		display: none;
	}
}

@media only screen and (min-width: 0px) and (max-width: 768px) {
	.profileViewContainer {
		.viewSection {
			.MuiAppBar-root{
				background-color: transparent;
				box-shadow: none;
			}
			.cardAction { margin-top: 2vh; }
		}
	}
}

@media only screen and (min-width: 0px) and (max-width: 425px) {
	.profileViewContainer {
		.viewSection {
			.profileDetailsSection { padding: 0 1rem; }
		}
	}
}

@media only screen and (min-width: 0px) and (max-width: 1269px) {
	.profileViewContainer {
		.Donebtn{
			font-family: var(--fontFamily) !important;
			background-color: var(--secondary) !important;
			color:var(--primary) !important;
			width: 100% !important;
			height: 55px !important;
			border-radius: 10px !important;
			font-size: var(--fontsizemedium) !important;
			text-transform: none !important;
			font-weight: bold !important;
			margin-top: -7px;
			margin-bottom: 20px;
			border: 1px solid var(--primary) !important;
			display: block;
		}
	}
}



