.PreferencesuccessPopup {
	position: absolute !important;
	background-color: white !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	outline: none !important;
	top: 50% !important;
	border-radius: 5px !important;
	/* width: 700px !important; */
	padding-left: 10px !important;
	padding-right: 10px !important;

	.CommonLoadermaingrid {
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 45px;

	}

	.successimage {
		text-align: center;
	}

	.heading {
		color: var(--primary) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		text-align: center !important;
	}

	.text1 {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left;
		padding-left: 60px;
		font-weight: 900;
	}

	.text2 {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: center !important;
		font-weight: 600;
	}

	.text3 {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: center !important;
		font-weight: normal;
	}
	.gridrow{
		margin-bottom:30px;
	}
}

@media only screen and (min-width: 0px) and (max-width: 1269px) {
	.PreferencesuccessPopup {
		position: absolute !important;
		background-color: white !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
		outline: none !important;
		top: 50% !important;
		border-radius: 5px !important;
		width: 85vw !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	

	.text1 {
		text-align: center;
		padding-left: 0px;
	}

	.text2 {
		text-align: center;
	}

	.text3 {
		text-align: center;
	}
}
}