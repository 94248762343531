.HeaderagentContainer {
	.HeaderMainContainer_Agent{
		position: fixed !important;
		height: 70px !important;
		width: 100vw !important;
		display: grid !important;
		align-items: center !important;
		background-color: var(--Agent_textcolor2) !important;
		border-bottom: 5px solid var(--Agent_bgcolor3) !important;
		z-index: 1000 !important;
	}

	
	.headingimgdiv_profile {
		height: 50px !important;
		width: 200px !important;
		margin-left: 25px;
	}

	.headingimgdiv_profileicon {
		height: 40px !important;
		width: 40px !important;
		border-radius: 100%;
	}

	.contentGrid {
		justify-content: center;
		display: flex;
		flex-direction: column;
	}

	.textlabel {
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--secondary) !important;
		text-align: right;
	}

	.textlabel1 {
		font-Family: var(--fontFamily) !important;
		font-size: 14px !important;
		color: var(--secondary) !important;
		text-align: right;
	}

	.headingimgdiv_profileicon {
		height: 40px !important;
		width: 40px !important;
		border-radius: 100%;
	}

	.Profileimage {
		width: 100%;
		height: 100%;
		object-fit: contain;
		margin-left: 10px;
		cursor: pointer;
	}
}

