.doctorParent{

	
	font-family: var(--fontFamily) !important;
	font-size: var(--fontsizesmall) !important;
	width: 100% !important;

	p{
		margin: 0 !important;
	}

	.doctorPageheading{
		font-weight: bold;
		background-color: var(--defaultgrey);
		box-shadow: 2px 1px 2px 0.1px #3f3f3f ;
		font-size: var(--fontsizemedium)!important;
		height: 40px !important;
	}
	
	.mydoctorSubheading{
		font-weight: bold;
	}

	/* .doctorDetails{
		
	} */

	.aboutDocmargin{
		margin-top: 10px !important;
	}

	.aboutDocFlex{
		display: flex !important;
	}

	.makeAppointment{
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 100% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium);
		text-transform: none !important;
		font-weight: bold !important;
	}

	.cancelAppointment{
		font-family: var(--fontFamily) !important;
		/* font-size: var(--fontsizemedium); */
		background-color: var(--secondary) !important;
		color: var(--primary) !important;
		height: 55px !important;
		width: 100% !important;
		border-radius: 10px !important;
		border: 1px solid var(--primary) !important;
		text-transform: none !important;
		font-weight: bold !important;
		font-size: var(--fontsizemedium) !important;
	}

	.docImage{
		object-fit: cover;
		width: 150px ;
		height: 150px;
		object-position: top;
		border-radius: 50%;
	}

	.upcommingAppointment{
		font-size: var(--fontsizesmall);
		background-color: var(--brandcolor) !important;
		color:white;
		padding: 10px;
		display:flex;
		min-height:40px;
		align-items: center;
		border-radius: 10px
	}

	.Donebtn{
		display: none;
	}

	@media (max-width: 899px) {
		.docName {
		text-align: center;
		margin-top: 10px !important;
		}
		.doctorPageheading{
			background: transparent !important;
			box-shadow: none !important;
			margin-top: 10px !important;
		}
	}

	@media only screen and (min-width: 0px) and (max-width: 1269px) {
			.Donebtn{
				font-family: var(--fontFamily) !important;
				background-color: var(--secondary) !important;
				color:var(--primary) !important;
				width: 100% !important;
				height: 55px !important;
				border-radius: 10px !important;
				font-size: var(--fontsizemedium) !important;
				text-transform: none !important;
				font-weight: bold !important;
				margin-top: -30px;
				margin-bottom: 20px;
				border: 1px solid var(--primary) !important;
				display: block;
			}
	}
	
}