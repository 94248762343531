
.accord-notifiction-container{
    font-family: var(--fontFamily) !important;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;

    *{
        font-family: var(--fontFamily) !important;
    }
   
    .header-nav-links{
        width: 95%;
        margin: 10px auto 0 auto;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .navigate-back{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(0, 71, 145, 1);
        text-transform: capitalize;
        padding: 0% !important;
    }
    .download-unresolved-navigate-container{
        width: 33%;
        display: flex;
        justify-content:flex-end;
        align-items: center;
    }
    .administrator-notifications{
        background-color: rgba(0, 69, 140, 1);
        border: 1px solid rgba(0, 69, 140, 1);
        border-radius: 4px;
        font-size: 14px !important;
        margin-right: 15px;
        text-transform: capitalize;
        letter-spacing: 1px;
        height: 45px;
		width:200px;
        
    }
    .hanger{
        position: absolute;
        top: -12px;
        right: -12px;
        font-size: 9px;
        font-weight: 400;
        font-family: var(--fontFamily) !important;
        padding: 5px 7px 3px 7px;
        background-color: rgba(179, 4, 4, 1);
        border: 0.5px solid white;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 14px;
    }
    .download-report{
        font-family: var(--fontFamily) !important;
        background-color: rgba(255, 255, 255, 1);
        border: 1px solid rgba(0, 69, 140, 1);
        border-radius: 4px;
        color: rgba(0, 69, 140, 1);
        font-size: 14px !important;
        text-transform: capitalize;
        padding-left:25px;
        padding-right: 25px;
        height: 45px;
		width:200px;
        align-self: flex-end;
    } 

    /* 2nd container design */

    .campaign-container{
        width: 95%;
        margin: 20px auto 0 auto;
    }
    .campaign-item-1{
        border: 1px solid rgba(216, 222, 228, 1);
        border-radius: 4px;
        padding: 0%;
        min-height: 310px;
    }
    .campaign-item-2{
        border: 1px solid rgba(216, 222, 228, 1);
        border-radius: 4px;
        padding: 0%;
		min-height: 310px;
        
    }
    .campaign-details-banner{
        width: 100%;
        background-color: rgba(237, 237, 237, 1);
        text-align: left;
        height: 50px;
        padding: 13px 0px 0px 0px;
        border-radius: 3px 3px 0px 0px;
        border: 0px 0px 1px 0px solid rgba(216, 222, 228, 1);
    }
    .banner-text{
        padding: 0%;
        padding-left:  20px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(38, 38, 38, 1);
        margin: 0;
    }
    .campaign-details data{
        width: 100%;
        padding: 20px;
    }
    .details-data-text-name{
        width: 90%;
        margin:22px auto 0 auto;
        font-size: 16px;
        font-weight: 600;
        line-height: 21.78px;
        text-align: left;
        color: rgba(41, 41, 41, 1);
        padding-top:9px;
    }
    .details-data-text-body{
        width: 89%;
        margin:15px auto 0 auto;
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.10000000149011612px;
        text-align: left;
        color: rgba(41, 41, 41, 1);
        line-height: 2.89;
        padding-bottom: 20px;
		/* overflow-y: auto;  */
		/* overflow-x: hidden;  */
		/* white-space: break-spaces;  */
		/* height: 180px; */
        
    }
    /* summary design */

    .summary-grid-container{
        width: 95%;
        margin: -5px auto 0 auto !important;
        padding-bottom: 10px;
    }
    .css-mhc70k-MuiGrid-root>.MuiGrid-item{
        padding-left : 0px !important;
    }
    .summary-grid-data{
        padding: 12px ;
        border: 1px solid rgba(220, 220, 220, 1);
        border-radius: 4px;
        box-shadow: none !important;
        font-size: 14px;
        color: rgba(0, 0, 0, 1);
        font-weight: 700;
        display: flex;
        justify-content: space-around;
        align-items: center;
        margin-top: 2px;

        /* height: 20px; */
    }
    .summary-data-point{
        width: 30%;
        text-align: right;
        padding-right: 0px;
        font-size: 20px;
    }
    .summary-data-variable{
        width: 60%;
        text-align: left;
        font-size: 14px;
        padding-left: 0%;
    }

    /* generate survey reports */

    .survey-results-container{
        width: 95%;
        margin: 20px auto 0 auto;
        border: 1px solid rgba(220, 220, 220, 1);
        border-radius: 4px;
        font-family: var(--fontFamily) !important;
    }
    .survey-details-banner{
        width: 100%;
        background-color: rgba(237, 237, 237, 1);
        text-align: left;
        height: 50px;
        padding: 15px 0 0px 0px;
        border-radius: 3px 3px 0px 0px;
        border: 0px 0px 1px 0px solid rgba(216, 222, 228, 1);
    }
    .survey-details-banner-text{
        padding: 0%;
        padding-left:  20px;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(38, 38, 38, 1);
        margin: 0;
    }
    .survey-data-container{
        width: 97%;
        margin: 0 auto 0 auto;
        padding: 25px 0px;
        overflow-y: auto;
    }
    .accordion-summary{
        background-color: rgba(237, 237, 237, 1);
        font-size: 14px;
        font-family: var(--fontFamily) !important;
        font-weight: 700;
        line-height: 25.5px;
        text-align: left;
    }
    .accordion-details{
        border:1px solid rgba(216, 222, 228, 1);
    }
    .data-box{
        border: 1px solid rgba(121, 121, 121, 0.3);
        border-radius: 8px;
        box-shadow: none;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 7px 0px !important;
        width: 98%;
        margin: 0 auto 0 auto;
    }
    .accordion-type-2-text{
        align-self: center;
        font-size: 14px;
        margin-left: 20px;
        font-weight: 600;
        color: rgba(0, 0, 0, 1);
        word-spacing:3px;
    }
    .accordion-type-2-number{
        border: 1px solid rgba(191, 191, 191, 1);
        border-radius: 8px;
        align-self: center;
        font-size: 18px;
        font-weight: 700;
        padding: 6px 20px;
        margin-right: 24px;
    }

    /* type 1 check all */

    .variable-name-container{
        display: flex;
        width: 29%;
        margin-left: 70%;
    }
    .variable-container{
        display: flex;
        width: 28%;
        margin-right: 20px;
        justify-content: space-between;
        align-items: flex-end;
    }
    .variable-1-data{
        width: 100px;
        border: 1px solid rgba(191, 191, 191, 1);
        border-radius: 8px;
        align-self: center;
        font-size: 18px;
        font-weight: 700;
        padding: 6px 20px;
        position: absolute;
        right: 40px;
        
        /* margin-right: 10%; */

    }
    .variable-2-data{
        width: 100px;
        border: 1px solid rgba(191, 191, 191, 1);
        border-radius: 8px;
        align-self: center;
        font-size: 18px;
        font-weight: 700;
        padding: 6px 15px;
        position: absolute;
        right: 15%;
        margin-right: 29px;
        
    }
    .open-sub-accord-btn{
        border: 1px solid rgba(0, 71, 145, 1);
        color: rgba(0, 71, 145, 1);
        padding: 1px 9px 3px 9px;
        border-radius: 8px;
        cursor: pointer;
        font-size: 162%;
    }
    .subaccordion-data-container{
        /* border: 1px solid rgba(121, 121, 121, 0.3); */
        border-top: none;
        border-radius: 0px 0px 8px 8px !important;
        box-shadow: none;
        width: 97.9%;
        margin: 0 auto 0 auto;
        background-color: rgba(237, 237, 237, 0.6);
    }
    .subaccordion-data-point{
        padding: 15px;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        text-align: left;
        padding-left: 40px;
        border-bottom: 1px solid rgba(121, 121, 121, 0.3);
        border-left: 1px solid rgba(121, 121, 121, 0.3);
        border-right: 1px solid rgba(121, 121, 121, 0.3);
        border-radius: 0px 0px 8px 8px !important;
    }

    @media print {
        .header-nav-links {
          display: none;
        }
      }
}
