.createPromotionPopupcontainer {
    position: absolute !important;
    background-color: white !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    outline: none !important;
    top: 50% !important;
    border-radius: 5px !important;
    width:var(--box-width, 70%);
    height:auto;
    max-height: 620px;
    overflow-y: scroll;
    padding-left: 0px !important;
    padding-right: 10px !important;


    .CommonLoadermaingrid {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 45px;

    }

    .MuiAccordion-root {
        margin: 0 !important;
        box-shadow: none;
    }

    ::before {
        display: none !important;
    }

    .GridIcon {
        text-align: right;
        cursor: pointer;
        padding-top: 2%;
        margin-top: 2%;
    }

    .accordian {
        margin-bottom: 5% !important;
    }

    .accordianDetails {
        background: white !important;
        padding: 0;
    }

    .MuiAccordionDetails-content {
        padding: 0 !important;
        box-shadow: none !important;
    }

    .MuiAccordionGroup-root {
        border: 0px !important;
    }

    .accordianSummary {
        background-color: #004791 !important;
        color: white !important;
        border-radius: 0 10px 10px 0 !important;
        /* padding: 20px 30px!important; */
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        min-height: 50px!important;
        opacity: 1!important;
    }

    .MuiAccordionSummary-content{
        margin:10px 0!important;
    }

    .questions {
        background-color: white;
        color: black;
        border-radius: 0 10px 10px 0 !important;
        padding: 5% 10% !important;
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        border: 1px solid #ececec;
        width: 100%;
    }

    .newQuestion{
        background-color: #2194F2;
        color: white;
        border-radius: 0 10px 10px 0 !important;
        padding: 5% 10% !important;
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        border: 1px solid #005BB9;
        width: 100%;
        text-decoration: underline;
    }

    .surveyBtn {
        background-color: white;
        color: #005BB9;
        border-radius: 0 10px 10px 0 !important;
        padding: 5% 10% !important;
        font-size: 14px !important;
        cursor: pointer !important;
        box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 8px !important;
        outline: none !important;
        margin-top: 1% !important;
        text-align: left;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-weight: bold;
        border: 1px solid #005BB9;
        width: 100%;
    }

    button.questions.active {
        background-color: #A1CFFF !important;
    }

    button.surveyBtn.active {
        background-color: #A1CFFF !important;
        color: black;
        border: none;
    }

    .MuiAccordion-root {
        border-bottom: none !important;
    }

    .MuiAccordionSummary-root {
        padding-top: 0 !important;
        padding-left: 10%!important;
    }

    .MuiGrid-root {
        padding-top: 0px;
        padding-right: 5px;
    }

    .label_text {
        height: 24px;
        font-weight: 600;
        font-size: 15px;
        margin-top: 1%;
    }

    .body_text_label{
        height: 24px;
        font-weight: 600;
        margin-top: 2%;
        font-size: 15px;
    }

    input {
        font-size: 14px !important;
        padding: 0px !important;

    }

    .MuiSelect-select {
        font-size: 14px !important;
        padding: 0px !important;
    }

    textarea {
        font-size: 14px !important;
        /* padding: 5px 5px!important; */
    }

    .questionBox {
        border: 1px solid #d3d3d3;
        /* padding: 30px; */
        border-radius: 4px;
        margin: 2% 0;
        position: relative;
    }

    .answerBox {
        /* border: 1px solid #d3d3d3; */
        padding: 0 5%;
        border-radius: 4px;
        margin-top: 2%;
    }

    .answerDiv{
        margin-top: 2%;
    }

    .richTextEditor {
        border: 1px solid #d3d3d3;
        padding: 0 10px;
        border-radius: 4px;
        margin-top: 5px;
    }

    .richTextEditorWelcomeThankyou{
        border: 1px solid #d3d3d3;
        padding: 0 10px;
        border-radius: 4px;
        margin-top: 2%;
    }

    .callbackBtnDiv{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-top: 2%;
        font-size: 14px;
    }

    .iconTickClose {
        width: 10px;
        height: 10px;
    }

    .iconAnswerAdd {
        width: 12px;
        height: 12px;
    }

    .tickBox,
    .closeBox {
        border: 1px solid #d3d3d3;
        padding: 2px 12px;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 999;
        background-color: white;
        align-items: center;
    }

    .addBox{
        border: 1px solid #d3d3d3;
        padding: 1px 8px;
        border-radius: 4px;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
        z-index: 999;
        background-color: white;
        align-items: center;
    }

    .addBoxMain{
        justify-content: flex-end;
        display: flex;
        padding: 1% 5% 5%;
    }

    .tickBox:hover,
    .closeBox:hover,
    .addBox:hover{
        cursor: pointer;
    }

    .closeBox {
        margin-left: 10%;
    }

    .scrollable-box {
        max-height: 300px;
        overflow-y: auto;
    }

    .scrollable-accordian {
        max-height: 300px;
        overflow-y: auto;
        display: block;
    }

    .nextBtn {
        width: 100%;
        color: white !important;
        margin-top: 5% !important;
    }

    .saveCancelDiv .Mui-disabled {
        font-size: 14px !important;
        opacity: 0.5!important;
        color: white!important;
    }

    .scrollbar-space {
        padding-right: 2%;
        padding-left: 6% !important;
    }

    .surveyDiv,.infoDiv {
        padding-right: 2%;
        padding-left: 8% !important;
        margin-top: 2%;
    }

    .infoDiv{
        color: red;
        text-align: center;
    }

    .surveyQuestionDiv {
        padding-right: 2%;
        padding-left: 6% !important;
    }

    .surveyDiv,
    .surveyQuestionDiv {
        align-items: center;
    }

    .surveyText {
        align-items: center;
        margin: 0;
    }

    .arrow {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        color: white;
        padding: 10px;
        cursor: pointer;
        transition: opacity 0.3s ease;
    }

    .arrowLeft {
        left: 2%;
        transform: rotate(90deg);
    }

    .arrowRight {
        right: -3%;
        transform: rotate(-90deg);
    }

    .accordianIcon {
        transform: rotate(180deg);
    }

    .descDiv {
        margin-top: 1%;
    }

    .smsDesc {
        margin: 0;
        font-size: 14px;
        color: #262626;
        ;
    }



    .Savebtn {
        font-family: var(--fontFamily) !important;
        font-size: var(--fontsizesmall);
        background-color: var(--Agent_bgcolor1) !important;
        width: 180px !important;
        height: 35px !important;
        border-radius: 5px !important;
        font-size: 14px;
        text-transform: none !important;
        font-weight: bold !important;
    }

    .saveCancelDiv {
        display: flex;
        justify-content: flex-end;
        margin-top: 4%;

    }

    .rdw-option-wrapper{
        padding: 0!important;
    }

    /* .textField{
        margin-top: 1%;
    } */

    .errorMsg{
        color: red;
        font-size: 14px;
    }

    .plusIcon{
        width: 12px;
        margin-right: 5px;
    }

    .rdw-editor-main {
        min-height: 100px;
        max-height: 200px;
        overflow-y: scroll;
    }

    .rdw-editor-toolbar{
        border: 1px solid #BCC5CC;
        margin-top: 5px;
    }

    .MuiInputBase-root{
        padding: 6px 10px;
    }

    /* Target webkit-based browsers (Chrome, Safari, newer versions of Opera) */
    ::-webkit-scrollbar {
        width: 6px;
        /* Width of the scrollbar */
    }

    /* Track (the area where the thumb rests) */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        /* Color of the track */
    }

    /* Thumb (the draggable scrolling element) */
    ::-webkit-scrollbar-thumb {
        background: #888;
        /* Color of the thumb */
        border-radius: 6px;
        /* Rounded corners of the thumb */
    }

    /* Optional: Hover state of the thumb */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
    }

    /* Container for the checkbox */
.checkbox-container {
  display: flex;
  align-items: center;
}

/* Hide the default checkbox */
.checkbox-input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Custom checkbox styling */
.checkbox-custom {
  width: 15px;
  height: 15px;
  background-color: #f0f0f0;
  border: 2px solid #ccc;
  border-radius: 4px;
  display: inline-block;
  margin-right: 4px;
  position: relative;
  vertical-align: middle;
  transition: background-color 0.3s, border-color 0.3s;
}

/* Checkbox checked state */
.checkbox-input:checked + .checkbox-custom {
  background-color: white; /* Green background when checked */
  /* border-color: #4CAF50;  */
}

/* Checkmark icon */
.checkbox-input:checked + .checkbox-custom::after {
  content: '';
  position: absolute;
  left: 3px;
  top: 4px;
  width: 8px;
  height: 4px;
  border: solid #004791;
  border-width: 0 0 2px 2px;
  transform: rotate(-45deg);
}

/* Label styling */
.checkbox-label {
  font-size: 14px;
  margin-left: 2px;
}


  .rdw-image-modal-btn {
    cursor: pointer;
    background: var(--Agent_bgcolor1) !important;
}

}


@media only screen and (min-width: 0px) and (max-width: 1269px) {
    .createPromotionPopupcontainer {
        position: absolute !important;
        background-color: white !important;
        left: 50% !important;
        transform: translate(-50%, -50%) !important;
        outline: none !important;
        top: 50% !important;
        border-radius: 5px !important;
        width: 85vw !important;
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
}