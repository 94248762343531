.scheduleCallbackParent {

	width: 100% !important;
	font-family: var(--fontFamily);

	.scheduleCallback {
		width: 100% !important;
		background-color: var(--btncolor) !important;
		font-size: var(--fontsizemedium) !important;
		color: var(--secondary);
		min-height: 55px !important;
		border-radius: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;

	}

}

.schCallbackModal {
	font-family: var(--fontFamily);
	font-size: var(--fontsizesmall);
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-60%, -50%);
	width: 500px;
	background: var(--secondary);
	border-radius: 10px;
	padding: 20px;

	.scheduleInput{
		padding: 0;
		width: 100% !important;
		background: var(--lightgrey);
		border-radius: 10px;
	}

	.memberInputlabel{
		color: var(--primary);
	}

	.memberCusomInput.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
	.memberCusomInput.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.memberCusomInput.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
		border-color: transparent !important;
	}
	



	.callbackConfirm {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 98% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		font-weight: bold !important;

	}

	.callbackCancel {
		font-family: var(--fontFamily) !important;
		background-color: var(--secondary) !important;
		color: var(--primary) !important;
		height: 55px !important;
		width: 98% !important;
		border-radius: 10px !important;
		border: 1px solid var(--primary) !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		font-weight: bold !important;

	}

	@media (max-width:799px){
		width: 80% !important;
	}

	@media (max-width:1299px){
		left:50% !important;
		transform: translate(-50%, -50%) !important;
	}

}