
.MemberSidebarcontainer{
.sidebardiv {
	margin-top: 50px;
}

.menuitemicon {
	width: 21px;
	height: 28px;
	margin-left: 15px;
}

.menuitemiconmini {
	width: 21px;
	height: 28px;
}

.toggleimagegrid {
	text-align: right;
}

.togglebtnimage {
	margin-right: 25px;
	margin-top: 35px;
	cursor: pointer;
}

.toggleimagegridmini {
	text-align: center;
}

.togglebtnimagemini {
	margin-top: 35px;
	cursor: pointer;
}

.sidemenuitemiconsubmenu {
	margin-left: 60px;
	width: 25px;
	height: 25px;
}

.sidemenuitemiconsubmenumini {
	width: 25px;
	height: 25px;

}

.sideitemmainrow {
	margin-bottom: 5px;
}

.action {
	font-size: var(--fontsizesmall) !important;
	font-family: var(--fontFamily) !important;
	text-decoration: none;
	color: var(--primary) !important;
	background: var(--secondary) !important;
	width: 270px;
	display: flex;
	height: 50px;
	justify-content: left;
	align-items: center;

}

.inAction {
	font-size: var(--fontsizesmall) !important;
	font-family: var(--fontFamily) !important;
	color: var(--secondary) !important;
	text-decoration: none;
	width: 270px;
	display: flex;
	height: 50px;
	justify-content: left;
	align-items: center;
}

.actionsubmenu {
	font-size: var(--fontsizesmall) !important;
	font-family: var(--fontFamily) !important;
	text-decoration: none;
	color: var(--primary) !important;
	background: var(--tertiary) !important;
	width: 270px;
	display: flex;
	height: 50px;
	justify-content: left;
	align-items: center;
}

.actionmini {
	text-decoration: none;
	color: var(--primary) !important;
	background: var(--secondary) !important;
	width: 75px;
	display: flex;
	height: 50px;
	align-items: center;
	justify-content: center;
}

.actionminisubmenu {
	font-size: var(--fontsizesmall) !important;
	font-family: var(--fontFamily) !important;
	text-decoration: none;
	color: var(--primary) !important;
	background: var(--tertiary) !important;
	width: 75px;
	display: flex;
	height: 50px;
	justify-content: center;
	align-items: center;
}

.inActionmini {
	color: #d4d4d4;
	text-decoration: none;
	width: 75px;
	display: flex;
	height: 50px;
	align-items: center;
	justify-content: center;
}

.bottomdiv {
	position: fixed;
	bottom: 35px;
	left: 35px;
}

.bottomdivmini{
	position: fixed;
	bottom: 35px;
	left: 15px;

}

.logoutbtn {
	background: var(--tertiary) !important;
	width: 200px;
	height: 40px;
	border-radius: 10px;
	font-size: var(--fontsizemedium) !important;
	font-family: var(--fontFamily) !important;
	color: var(--primary) !important;
	text-transform: none !important;
	font-weight: bold;
}

.logoutbtnmini{
	background: var(--tertiary) !important;
	height: 40px;
	min-width: 50px;
    max-width: 50px;
	border-radius: 10px;
}



.lastlogin {
	font-size: 14px !important;
	font-family: var(--fontFamily) !important;
	color: var(--secondary) !important;
}

::-webkit-scrollbar {
	width: 5px;
	/* Set the width of the scrollbar */
}

::-webkit-scrollbar-track {
	background: #f1f1f1;
	/* Track color */
}

::-webkit-scrollbar-thumb {
	background: #888;
	/* Thumb color */
	border-radius: 5px;
	/* Round the corners of the thumb */
}

.sidebarscrollbody {
	height: calc(100vh - 200px) !important;
	overflow-y: auto;
	overflow-x: hidden;
	direction: rtl;
}

.scrollable-content {
	direction: ltr;
}
}