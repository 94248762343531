.appointmentTable{
	.tableHead{
		background-color: var(--brandcolor);
		border-radius: 10px;
		
		
	}
	.tableHeadcell{
		background-color: var(--brandcolor);
		/* border: 1px solid var(--brandcolor); */
		color: var(--secondary) !important;
		font-size: var(--fontsizesmall);
		/* border-radius: 5px !important; */

	}
	
	.tablerowcell{
		border-bottom: 1px solid black !important;
		font-size: var(--fontsizesmall);
	}
	
	@media (max-width: 500px) {
		.tableHeadcell,
		.tablerowcell{
			padding: 6px !important;
		}
		
	}

}
