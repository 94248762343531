.SurveyTemplate5container {
	.Survey_bodygrid_Template1 {
		padding-right: 25vw !important;
		padding-left: 25vw !important;
		padding-top: 25px;
	}
	.Heading {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}
	.questiontext{
		color: var(--Secondary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left;
		line-height: 22px;
	}

	.optionsbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--Primary) !important;
		color:var(--Secondary) !important;
		min-height: 70px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizesmall) !important;
		text-transform: none !important;
		border: 1.5px solid #676767 !important;
		width: 100% !important;
		text-transform: none !important;
		font-weight: normal !important;
		margin-bottom: 10px;
	}

	.optionsselectedbtn{
		font-family: var(--fontFamily) !important;
		background-color: var(--lightblue) !important;
		color:var(--Secondary) !important;
		min-height: 70px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizesmall) !important;
		text-transform: none !important;
		border: 1.5px solid #676767 !important;
		width: 100% !important;
		text-transform: none !important;
		font-weight: bold !important;
		margin-bottom: 10px;
	}

	.textarea{
		border-radius: 10px !important;
		border: 1.5px solid #676767 !important;
		width: 100% !important;
		height: 60px !important;
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		margin-bottom: 10px;
		padding-top: 15px;
	}

	.datepicker{
		width:100%;
		border-radius: 10px !important;
		border: 1.5px solid #676767 !important;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}

	.datepicker Input{
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		/* font-weight: bold; */
	}

	.Nextbtn{
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		height: 55px !important;
		width: 100% !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
	}
	.disablebtn{
		font-family: var(--fontFamily) !important;
		background-color: #989898 !important;
		width: 100% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		bottom: 10px !important;
		text-transform: none !important;
		color: #000000 !important;
		font-weight: bold !important;
		pointer-events: none;
		cursor: not-allowed;
	}
	.idontknowtext{
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizemedium) !important;
	    cursor: pointer;
		border-bottom: 1px solid black;
	}
}

@media only screen and (min-width: 0px) and (max-width: 1269px) {
	.SurveyTemplate4container {
		.Survey_bodygrid_Template1 {
			padding-right: 8vw !important;
			padding-left: 8vw !important;
		}
 }
}