.PreferrenceContainerMember {
	p{
		margin: 0 !important;
	}
	.memberPageheading{
		font-weight: bold;
		background-color: var(--defaultgrey);
		box-shadow: 2px 1px 2px 0.1px #3f3f3f ;
		font-size: var(--fontsizemedium)!important;
		height: 40px !important;
	}
	/* .maingridcontainer {
		margin-top: 18vh;
	} */

	.Gridcontent{
		margin-bottom: 25px;
	}

	.gridtext{
		text-align: left;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
		font-weight: bold;
		margin-top: 15px;
	}

	.Drpdownselect {
		background-color: var(--defaultgrey) !important;
		width: 100% !important;
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
	}
	.MuiSelect-select {
		font-weight: bold;
	  }

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}

	.textlabel {
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
		margin-top: 5px;
	}

	.submitbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 100% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		font-weight: bold !important;
		margin-bottom: 20px;
	}

	.disablebtn{
		font-family: var(--fontFamily) !important;
		background-color: #989898 !important;
		width: 100% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		color: #000000 !important;
		font-weight: bold !important;
		pointer-events: none;
		cursor: not-allowed;
		margin-bottom: 20px;
	}
	

	@media (max-width: 899px){

		.memberPageheading{
			background: transparent !important;
			box-shadow: none !important;
			margin-top: 10px !important;
		}

	}

}