.AgentLogincontainer {

	.boxcontent {
		font-weight: bold !important;
		text-align: left !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
	}
	.flexGrid{
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	.gridLogin {
		text-align: center !important;

	}

	.Loginbody {
		margin-left: 7vw !important;
		margin-right: 25px !important;
	}

	.inputlabel {
		text-align: left !important;
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--primary) !important;
	}

	.inputcode Input{
		font-weight: bold;

	}

	.textbox1 {
		width: 35vw !important; 
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		font-weight: bold !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 10px !important;
		text-align: left !important;
	}

	.customInput {
		width: 35vw !important;
		/* font-weight: bold !important; */
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		padding-left: 0px !important;
	}

	.customDatePicker input {
		/* font-weight: bold !important; */
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		padding-left: 5px !important;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}


	.textbox1error {
		width: 35vw !important;
		border: 2px solid red !important;
		font-family: var(--fontFamily) !important;
	}

	.textbox2 {
		width: 35vw !important;
		background-color: var(--defaultgrey) !important;
		font-family: var(--fontFamily) !important;
		border-radius: 10px !important;
		text-align: left !important;
	}

	.textbox2error {
		border: 1px solid red !important;
		width: 35vw !important;
		background-color: var(--defaultgrey) !important;
		font-family: var(--fontFamily) !important;
		border-radius: 10px !important;
		text-align: left !important;
	}
	.textbox3 {
		width: 35vw !important;
		background-color: var(--defaultgrey) !important;
		font-family: var(--fontFamily) !important;
		border-radius: 10px !important;
		text-align: left !important;
	}

	.textbox3error {
		border: 1px solid red !important;
		width: 35vw !important;
		background-color: var(--defaultgrey) !important;
		font-family: var(--fontFamily) !important;
		border-radius: 10px !important;
		text-align: left !important;
	}



	.submitbtn {
		font-family: var(--fontFamily) !important;
		background-color: #004791 !important;
		width: 35vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizelarge) !important;
		text-transform: none !important;
		font-weight: bold !important;
		margin-top: 20px;
		margin-left: -5px;
	}

	.submitbtndisabled {
		font-family: var(--fontFamily) !important;
		background-color: #989898 !important;
		width: 35vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizelarge) !important;
		text-transform: none !important;
		color: #000000 !important;
		font-weight: bold !important;
		margin-top: 20px;
		margin-left: -5px;
		pointer-events: none;
	}


	

	.errormsg {
		font-family: var(--fontFamily) !important;
		color: red !important;
		text-align: left !important;
		margin-top: 5px !important;
		width: 35vw !important;
	}

	.customiconcalender {
		position: absolute !important;
		margin-left: 20px !important;
		z-index: 0 !important;
		margin-top: 19px !important;
		text-align: left !important;
	}
	.popcustombutton {
		height: 50px;
		width: 390px;
		background-color: #004791;
		margin-bottom: 20px;
		font-family: 'Inter', sans-serif;
		font-size: 18px;
		text-transform: none;
		border-radius: 10px;
		color: white; /* Ensure text color contrasts with the background */
	}
	
	.MuiDialog-container {
		position: absolute;
		top: 50%;
		right: 20px; /* adjust this value to position the dialog relative to the right grid */
		transform: translateY(-50%);
		margin: 0;
		max-width: 540px;
		margin-right:7%;
		height: 80vh; /* Adjust this value as needed */
		max-height: 80vh; /* Ensure the height adjustment takes effect */
		
	  }
	  .MuiDialog-paper {
		/* height: 45%; */
	}

}
