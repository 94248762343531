@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');


.App {
	text-align: center;
}

.App-logo {
	height: 40vmin;
	pointer-events: none;
}

/* Member */
.ComponentMainContainer {
	padding-left: 0;
	padding-top: 75px;
}

.ComponentMainContainerclose {
	padding-left: 0;
	padding-top: 75px;
}


.HeaderMainContainer {
	position: fixed !important;
	height: 70px !important;
	width: 100vw !important;
	display: grid !important;
	align-items: center !important;
	background-color: var(--brandcolor) !important;
	border-bottom: 5px solid var(--tertiary) !important;
	z-index: 1000 !important;

}

.SidebarMainContainer {
	height: 100vh !important;
	position: fixed !important;
	top: 71px !important;
	width: 270px !important;
	background-color: var(--brandcolor) !important;
	z-index: 1000 !important;


}

.SidebarMainContainerclose {
	height: 100vh !important;
	position: fixed !important;
	top: 71px !important;
	width: 75px !important;
	background-color: var(--brandcolor) !important;
	z-index: 1000 !important;

}



/* on boarding Module */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
}

input[type=number] {
    -moz-appearance: textfield;
}


.Linierprogress {
	height: 10px !important;
	background-color: var(--defaultgrey) !important;
	border-radius: 5px;
	width: 85vw !important
}

.Linierprogress .MuiLinearProgress-bar {
	background-color: var(--brandcolor) !important;
}

.MuiRadio-colorPrimary.Mui-checked {
	color: var(--brandcolor) !important;
}



.CircularProgresscenterdiv {
	position: fixed !important;
	top: 0 !important;
	left: 0% !important;
	width: 100% !important;
	height: 100% !important;
	z-index: 1000 !important;
	display: flex !important;
	justify-content: center !important;
	align-items: center !important;
	backdrop-filter: blur(1px); /* Apply blur effect */
	-webkit-backdrop-filter: blur(1px); /* For Safari */
}

.CircularProgresscenter {
	color: var(--brandcolor) !important;
	width: 80px !important;
	height: 80px !important;
}


/* common css for header */
.HeaderContainer {
	.headerGrid-Profile {
		height: 12vh !important;
		background-color: var(--brandcolor) !important;
		border-bottom: 5px solid var(--tertiary) !important;
		place-items: center !important;
		text-align: center;
	}

	.headingcol2_profile {
		display: grid !important;
		place-items: end !important;
	}

	.headingimgdiv_profile {
		height: 50px !important;
		width: 200px !important;
		margin-left: 25px;
	}

	.headingimgdiv2_profile {
		height: 25px !important;
		width: 50px !important;
		margin-right: 25px;
	}
}


/* css for login */

.logincontainer {
	.headerGrid {
		height: 25vh !important;
		background-color: var(--brandcolor) !important;
		border-bottom: 5px solid var(--tertiary) !important;
		display: grid !important;
		place-items: center !important;
	}

	.headingimgdiv {
		height: 80px !important;
		width: 250px !important;
	}

	.boxcontent {
		height: 50px !important;
		margin-left: 7vw !important;
		margin-right: 25px !important;
		font-weight: bold !important;
		text-align: left !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
	}

	.gridLogin {
		text-align: center !important;

	}

	.Loginbody {
		margin-left: 7vw !important;
		margin-right: 25px !important;
	}

	.inputlabel {
		text-align: left !important;
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--primary) !important;
	}

	.textbox1 {
		width: 85vw !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		font-weight: bold !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 10px !important;
		text-align: left !important;
	}

	.customInput {
		width: 85vw !important;
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		padding-left: 0px !important;
	}

	.customDatePicker input {
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		padding-left: 5px !important;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}


	.textbox1error {
		width: 85vw !important;
		border: 2px solid red !important;
		font-family: var(--fontFamily) !important;
	}

	.textbox2 {
		width: 85vw !important;
		background-color: var(--defaultgrey) !important;
		font-family: var(--fontFamily) !important;
		border-radius: 10px !important;
		text-align: left !important;
	}

	.textbox2error {
		border: 1px solid red !important;
		width: 85vw !important;
		background-color: var(--defaultgrey) !important;
		font-family: var(--fontFamily) !important;
		border-radius: 10px !important;
		text-align: left !important;
	}



	.submitbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizelarge) !important;
		text-transform: none !important;
		font-weight: bold !important;
		margin-top: 20px;
		margin-left: -5px;
	}

	.submitbtndisabled {
		font-family: var(--fontFamily) !important;
		background-color: #989898 !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizelarge) !important;
		text-transform: none !important;
		color: #000000 !important;
		font-weight: bold !important;
		margin-top: 20px;
		margin-left: -5px;
	}


	.submitbtn_Member {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizelarge) !important;
		text-transform: none !important;
		font-weight: bold !important;
		margin-top: 20px;
		margin-left: -5px;
	}

	.submitbtndisabled_Member {
		font-family: var(--fontFamily) !important;
		background-color: #989898 !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizelarge) !important;
		text-transform: none !important;
		color: #000000 !important;
		font-weight: bold !important;
		margin-top: 20px;
		margin-left: -5px;
	}

	.errormsg {
		font-family: var(--fontFamily) !important;
		color: red !important;
		text-align: left !important;
		margin-top: 5px !important;
	}

	.customiconcalender {
		position: absolute !important;
		margin-left: 20px !important;
		z-index: 0 !important;
		margin-top: 19px !important;
		text-align: left !important;
	}

}

/* ////////////////////// */

/* css for welcome */
.Welcomecontainer {
	.headerGrid {
		height: 25vh !important;
		background-color: var(--brandcolor) !important;
		border-bottom: 5px solid var(--tertiary) !important;
		display: grid !important;
		place-items: center !important;
	}

	.headingimgdiv {
		height: 80px !important;
		width: 250px !important;
	}

	.bodygrid {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
	}

	.welcometext {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--primary) !important;
		text-align: left !important;
	}

	.submitbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		position: fixed !important;
		transform: translate(-50%, -50%) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
		;
	}


}

/* //////////////////////////// */

/* css for profile */
.Profilecontainer {

	.bodygrid_profile {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
		overflow-y: auto !important;
		max-height: calc(88vh - 180px) !important;

	}

	.Profileinfo {
		color: var(--primary) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.text1_profile {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;

	}

	.text2_profile {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		margin-top: 5px !important;
		text-align: left !important;
	}

	.informationbox {
		background-color: var(--defaultgrey) !important;
		text-align: left !important;
		padding-right: 25px !important;
		padding-left: 25px !important;
		border-radius: 5px !important;
	}

	.namelabel {
		color: var(--primary) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.namelabel1 {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.namelabel2 {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
	}

	.Correctbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		position: fixed !important;
		bottom: 10px !important;
		transform: translate(-50%, -120%) !important;
		font-weight: bold !important;
		line-height: 20px !important;
		margin-bottom: 10px !important;

	}

	.EditProfilebtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--secondary) !important;
		color: var(--primary) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		font-weight: bold !important;
		position: fixed !important;
		bottom: 10px !important;
		transform: translate(-50%, -10%) !important;
		font-weight: bold !important;
		border: 1px solid black !important;
		line-height: 20px;
	}

}

/* //////////////////////// */

/* css for Edit profile Information */
.EditProfilecontainer {
	.bodygrid_profileInformation {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
		overflow-y: auto !important;
		max-height: calc(88vh - 180px) !important;
		overflow-x: hidden !important;

	}

	.Profileinfo {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.bodyformgrid {
		text-align: left !important;
	}

	.aligncontainer {
		display: flex !important;
		justify-content: flex-start !important;
	}


	.textlabel1 {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.inputlabel {
		text-align: left !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
	}
	.memberInputlabel{
		font-size: var(--fontsizesmall);
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
		padding: 0 !important;
		padding-bottom: 5px !important;
	}

	.textbox1,
	.textbox1city,
	.textbox1Streetaddress {
		width: 82vw !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		font-weight: bold !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 10px !important;
		text-align: left !important;

	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}


	.customInput {
		width: 82vw !important;
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		border-radius: 10px !important;
	}

	.textbox2
      {
		width: 40vw !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		font-weight: bold !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 10px !important;
		text-align: left !important;

	}
	.customInput1 {
		width: 40vw !important;
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		border-radius: 10px !important;
	}


	.textboxzipcode {
		width: 40vw !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		font-weight: bold !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 10px !important;
		text-align: left !important;
	}

	.customInputzipcode {
		width: 40vw !important;
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		border-radius: 10px !important;
	}

	.inputlabelDrpdownselect {
		text-align: left !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
		margin-top: 10px !important;
	}

	.MuiSelect-select {
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
	}

	.errormsg {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: red !important;
		text-align: left !important;
		margin-top: 5px !important;
	}


	.Drpdownselect {
		width: 40vw !important;
		margin-top: 10px !important;
		border-radius: 10px !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
	}

	.nextbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		position: fixed !important;
		transform: translate(-50%, -50%) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
	}

}

/* /////////////////////////////////// */

/* css for .Addresscontainer */

.Addresscontainer {
	.bodygrid_AdressInformation {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
		overflow-y: auto !important;
		max-height: calc(88vh - 180px) !important;
		overflow-x: hidden !important;
	}

	.Profileinfo {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	/* for checkbox label */
	.MuiFormControlLabel-label {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;
	}

	.text1_profile {
		color: #414141 !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;

	}

	.informationboxAddress {
		background-color: var(--defaultgrey) !important;
		text-align: left !important;
		border-radius: 5px !important;
		width: 85vw !important
	}

	.namelabel1 {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		padding-left: 25px !important;
	}

	.namelabel2 {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		padding-left: 25px !important;
	}

	.checkitemdiv {
		text-align: left !important;
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
	}

	.imageline {
		text-align: left !important;
		background-color: var(--brandcolor) !important;
		height: 330px !important;
		width: 2px !important;
		margin-left: 8px !important;
	}

	/* textbox css */

	.inputlabel {
		text-align: left !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}

	.textbox1 {
		width: 77vw !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		height: 60px !important;
		font-weight: bold !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 10px !important;
		text-align: left !important;

		.customInput {
			width: 77vw !important;
			font-weight: bold !important;
			font-Family: var(--fontFamily) !important;
			font-size: var(--fontsizesmall) !important;
			border-radius: 10px !important;
		}
	}



	.errormsg {
		font-family: var(--fontFamily) !important;
		color: red !important;
		text-align: left !important;
		margin-top: 5px !important;
	}


	/* textbox css end */

	.textboxzipcode {
		width: 37.5vw !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		font-weight: bold !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 10px !important;
		text-align: left !important;

		.customInput {
			width: 37.5vw !important;
			font-weight: bold !important;
			font-Family: var(--fontFamily) !important;
			font-size: var(--fontsizesmall) !important;
			border-radius: 10px !important;
		}
	}

	.errormsgzip {
		font-family: var(--fontFamily) !important;
		color: red !important;
		text-align: left !important;
		margin-top: 5px !important;
		width: 35vw !important;
	}

	.inputlabelDrpdownselect {
		text-align: left !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
		margin-top: 10px !important;
	}

	/*  for text inside select */
	.MuiSelect-select {
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
	}

	.Drpdownselect {
		width: 38vw !important;
		margin-top: 10px !important;
		border-radius: 10px !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
	}

	.nextbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		position: fixed !important;
		transform: translate(-50%, -50%) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
		;
	}

	.bodygrid_address {
		text-align: left !important;
	}


}

/* /////////////////////////////////// */

/* css for .contactcontainer */
.Contactcontainer {
	.bodygrid_ContactInformation {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
		overflow-y: auto !important;
		max-height: calc(88vh - 180px) !important;
		overflow-x: hidden !important;
	}

	.Profileinfo {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.text1_profile {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;

	}

	.informationboxAddress {
		height: 60px !important;
		background-color: var(--defaultgrey) !important;
		text-align: left !important;
		width: 85vw !important;
		border-radius: 5px !important;
	}

	.namelabel2 {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		padding-left: 25px !important;
	}

	.checkitemdiv {
		text-align: left !important;
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
	}

	/* css for checkboc item */
	.MuiFormControlLabel-label {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;
	}

	.imageline {
		text-align: left !important;
		background-color: var(--brandcolor) !important;
		height: 160px !important;
		width: 2px !important;
		margin-left: 8px !important;
	}

	/* textbox css */

	.inputlabel {
		text-align: left !important;
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--brandcolor) !important;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}

	.textbox1 {
		width: 78vw !important;
		background-color: var(--defaultgrey) !important;
		border: transparent !important;
		font-weight: normal !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		border-radius: 5px !important;
		text-align: left !important;

		.customInput {
			width: 78vw !important;
			font-weight: normal !important;
			font-Family: var(--fontFamily) !important;
			font-size: var(--fontsizesmall) !important;
			border-radius: 10px !important;
		}
	}

	.errormsg {
		font-family: var(--fontFamily) !important;
		color: red !important;
		text-align: left !important;
		margin-top: 5px !important;
	}


	.textbox1error {
		width: 78vw !important;
		border: 2px solid red !important;
		font-family: var(--fontFamily) !important;
	}

	/* textbox css end */

	.nextbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		position: fixed !important;
		transform: translate(2%, -50%) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
	}

}


/* /////////////////////////////////// */

/* css for .Prefferedchannelcontainer */
.Preferredchannelcontainer {
	.bodygrid_Preferredchannel {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
		overflow-y: auto !important;
		max-height: calc(88vh - 180px) !important;
	}

	.bodygrid_Preferredchannelnoemailphone {
		padding-right: 8vw !important;
		padding-left: 8vw !important;

	}

	.Profileinfo {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.textheading {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: left !important;
	}

	.textheadingnoemailphone {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: center !important;
		align-content: center !important;
		height: 65vh !important;
	}

	.text1_profile {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;

	}


	.dynamicMessagebtn {
		font-family: var(--fontFamily) !important;
		width: 85vw !important;
		/* height: 85px !important; */
		min-height: 80px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizesmall) !important;
		text-transform: none !important;
		border: 1px solid var(--defaultgrey) !important;
		margin-top: 15px !important;
		display: grid !important;
		align-items: center !important;
		border: 1.5px solid #676767 !important;

	}

	.nextbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		position: fixed !important;
		transform: translate(-50%, -50%) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
	}

}

/* /////////////////////////////////// */

/* css for .Prefferedchannelcontainer */

.Preferredfrequencycontainer {
	.bodygrid_Preferredfrequency {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
		max-height: calc(88vh - 180px) !important;
		overflow-y: auto !important;
	}

	.Profileinfo {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.textheading {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: left !important;
	}

	.text1_profile {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;

	}

	.mainspan {
		margin-left: 15px !important;
		margin-right: 15px !important;
	}

	.heading {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--brandcolor) !important;
		font-weight: bold !important;
	}

	.subheading {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--brandcolor) !important;
	}

	.dynamicMessagebtn {
		font-family: var(--fontFamily) !important;
		width: 85vw !important;
		/* height: 117px !important; */
		min-height: 80px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizesmall) !important;
		text-transform: none !important;
		border: 1px solid var(--defaultgrey) !important;
		margin-top: 15px !important;
		color: var(--brandcolor) !important;
		display: grid !important;
		align-items: center !important;
		text-align: left !important;
		border: 1.5px solid #676767 !important;
	}

	.nextbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		position: fixed !important;
		transform: translate(-50%, -50%) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
	}
}

/* /////////////////////////////////// */

/* css for .LanguageConfirmation */
.registerContainer {

	.headerGrid {
	  height: 100vh !important;
	  background-color: var(--brandcolor) !important;
	  border-bottom: 5px solid var(--tertiary) !important;
	  display: flex !important; /* Use flexbox for centering */
	  align-items: center !important; /* Vertically center */
	  justify-content: center !important; /* Horizontally center */
  }
   
	.headingimgdiv {
	  display: flex !important; /* Use flexbox for centering */
	  align-items: center !important; /* Vertically center */
	  justify-content: center !important; /* Horizontally center */
	  height: 250px !important;
	  width: 250px !important;
  }

   

   .boxcontent {
	  margin-top: 30px;
	  height: 50px !important;
	  /* margin-left: 7vw !important;
	  margin-right: 25px !important; */
	  font-weight: bold !important;
	  text-align: center !important;
	  font-size: var(--fontsizemedium) !important;
	  font-family: var(--fontFamily) !important;
  }

   
   
  .gridLogin {
	  text-align: center !important;
	  width: 100%;

  }

	.Loginbody {
	  margin-left: 0vw;
	  margin-right: 0px;
	}
   
	.inputlabel {
	  text-align: left;
	  font-family: var(--fontFamily);
	  font-size: var(--fontsizesmall);
	  color: var(--primary);
	}
   
	.textbox1 {
	  /* width: 30vw !important; */
	  max-width: 90vw;
	  background-color: var(--defaultgrey) !important;
	  border: transparent !important;
	  font-weight: bold !important;
	  font-family: var(--fontFamily) !important;
	  margin-top: 5px !important;
	  border-radius: 10px !important;
	  text-align: left !important;
  }
   
   .customInput {
	  /* width: 100% !important; */
	  font-weight: bold !important;
	  font-Family: var(--fontFamily) !important;
	  font-size: var(--fontsizesmall) !important;
	  padding-left: 0px !important;
  }
   
	.customDatePicker input {
	  font-weight: bold;
	  font-family: var(--fontFamily);
	  font-size: var(--fontsizesmall);
	  padding-left: 5px;
	  color: var(--primary);
	}
   
	.MuiOutlinedInput-notchedOutline {
	  border: none;
	}
   
   .textbox1error {
	  width: 85vw !important;
	  border: 2px solid red !important;
	  font-family: var(--fontFamily) !important;
  }
   
   .textbox2 {
	  width: 85vw !important;
	  background-color: var(--defaultgrey) !important;
	  font-family: var(--fontFamily) !important;
	  border-radius: 10px !important;
	  text-align: left !important;
  }
   
	.textbox2error {
	  border: 1px solid red !important;
	  width: 85vw !important;
	  background-color: var(--defaultgrey) !important;
	  font-family: var(--fontFamily) !important;
	  border-radius: 10px !important;
	  text-align: left !important;
  }

   
	.submitbtn {
	  font-family: var(--fontFamily) !important;
	  background-color: var(--btncolor) !important;
	  width: 100% !important;
	  height: 55px !important;
	  border-radius: 10px !important;
	  font-size: var(--fontsizelarge) !important;
	  /* position: fixed !important;
	  transform: translate(-50%, -50%) !important; */
	  /* bottom: 210px !important; */
	  text-transform: none !important;
	  font-weight: bold !important;;
  }
   
	.submitbtndisabled {
	  font-family: var(--fontFamily) !important;
	  background-color: #989898 !important;
	  width: 100% !important;
	  height: 55px !important;
	  border-radius: 10px !important;
	  font-size: var(--fontsizelarge) !important;
	  /* position: fixed !important; */
	  /* transform: translate(-50%, -50%) !important; */
	  /* bottom: 210px !important; */
	  text-transform: none !important;
	  color: #000000 !important;
	  font-weight: bold !important;
  }
   
   .errormsg {
	  font-family: var(--fontFamily) !important;
	  color: red !important;
	  text-align: left !important;
	  margin-top: 5px !important;
  }
	.customiconcalender {
	  position: absolute !important;
	  margin-left: 20px !important;
	  z-index: 0 !important;
	  margin-top: 19px !important;
	  text-align: left !important;
  }
}

.LanguageConfirmation {
	.bodygrid_LanguageConfirmation {
		padding-right: 8vw !important;
		padding-left: 8vw !important;
		overflow-y: auto !important;
		max-height: calc(88vh - 180px) !important;
	}

	.Profileinfo {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.textheading {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: left !important;
	}

	.text1_profile {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: left !important;

	}

	.Drpdownselect {
		background-color: var(--defaultgrey) !important;
		width: 85vw !important;
		height: 80px !important;
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}


	.nextbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 85vw !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		position: fixed !important;
		transform: translate(-50%, -50%) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
		font-weight: bold !important;
	}

}

.CommonLoader {
	.messageSec {
		height: 88vh;
		display: grid;
		align-content: center;
		padding-right: 8vw;
		padding-left: 8vw;
		font-size: var(--fontsizemedium);
	}

	.biggerFont {
		font-size: var(--fontsizelarge) !important;
	}

	.underline {
		font-weight: 600;
		border-bottom: 2px solid #090947;
		cursor: pointer;
	}

	.btnThankYou {
		width: 100%;
		height: 65px;
		border-radius: 10px;
	}

	.btnScheApp:hover {
		background-color: #98A2B3;
	}

	.btnTalkAge:hover {
		background-color: #98A2B3;
	}

	.btnGrpDiv {
		position: fixed;
		bottom: 10px;
	}

	.statementText {
		margin-bottom: 2.5rem;
		padding-top: 2rem;
		line-height: 2rem;
		font-family: var(--fontFamily) !important;
	}
}

.SuccessPopupcontainer {
	position: absolute !important;
	background-color: white !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	outline: none !important;
	top: 50% !important;
	border-radius: 5px !important;

	.CommonLoadermaingrid {
		display: flex !important;
		align-content: center !important;
		align-items: center !important;
		height: 500px !important;
		width: 340px !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.successimage {
		text-align: center !important;
	}

	.namelabeltitle {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: center !important;
	}

	.namelabel {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizelarge) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: center !important;
	}

	.namelabel1 {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: center !important;
	}

	.namelabel2 {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		margin-top: 5px !important;
		font-weight: bold !important;
		text-align: center !important;
	}
}

.SuccessPopupcontainer1 {
	position: absolute !important;
	background-color: white !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	outline: none !important;
	top: 50% !important;
	border-radius: 5px !important;

	.CommonLoadermaingrid {
		display: flex !important;
		align-content: center !important;
		align-items: center !important;
		width: 340px !important;
		height: 400px !important;
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.successimage {
		text-align: center !important;
	}

	.namelabeltitle {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		text-align: center !important;
	}

	.namelabel {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: center !important;
	}

	.namelabel1 {
		color: var(--primary) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: center !important;
	}

	.namelabelFade {
		color: var(--darkgrey) !important;
		font-size: var(--fontsizesmall) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
		text-align: center !important;
	}
}

.fontPopupcontainer {
	position: absolute !important;
	bottom: 0% !important;
	left: 50% !important;
	transform: translate(-50%, -0%) !important;
	background-color: white !important;
	width: 100% !important;
	border-radius: 30px !important;
	outline: none !important;

	.textlabel {
		/* color: var(--primary) !important; */
		font-family: var(--fontFamily) !important;
		text-align: center !important;
	}

	.bodygrid {
		padding-left: 25px !important;
		padding-right: 25px !important;
		cursor: pointer !important;
	}

	.linediv {
		border-bottom: 1px solid #000000 !important;
		margin-top: 10px !important;
	}
}

@media (max-width: 768px) {
	.Profilecontainer {
	  .informationbox {
		margin-bottom: 50px !important;
	  }
	}
  }

@media only screen and (min-width: 768px) and (max-width: 5000px) {
	.HeaderContainer {
		.headingcol2_profile {
			display: none !important;
		}

		.btnThankYou {
			width: 60% !important;
		}
	}

	.SuccessPopupcontainer {
		.CommonLoadermaingrid {
			display: flex !important;
			align-content: center !important;
			align-items: center !important;
			width: 500px !important;
			height: 500px !important;
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}

	.SuccessPopupcontainer1 {
		.CommonLoadermaingrid {
			display: flex !important;
			align-content: center !important;
			align-items: center !important;
			width: 500px !important;
			height: 400px !important;
			padding-left: 10px !important;
			padding-right: 10px !important;
		}
	}
}

@media only screen and (min-width: 1200px) and (max-width: 5000px) {

	.ComponentMainContainer {
		padding-left: 270px;
		padding-top: 75px;

	}

	.ComponentMainContainerclose {
		padding-left: 75px;
		padding-top: 75px;
	}

	.messageSec {
		padding-right: 25vw !important;
		padding-left: 25vw !important;
	}

	.Linierprogress {
		width: 50vw !important
	}

	.logincontainer {
		height: 100vh !important;
		overflow: hidden !important;

		.customInput {
			width: 35vw !important;
			font-size: var(--fontsizesmall) !important;
		}

		.customDatePicker input {
			font-size: var(--fontsizesmall) !important;
		}

		.headerGrid {
			height: 104vh !important
		}

		.boxcontent {
			margin-top: 4vw !important;
		}

		.headingimgdiv {
			height: 350px !important;
			width: 400px !important;
		}

		.textbox1 {
			width: 35vw !important
		}

		.textbox2 {
			width: 35vw !important
		}

		.textbox1error {
			width: 35vw !important;
			border: 2px solid red !important;
		}

		.textbox2error {
			border: 1px solid red !important;
			width: 35vw !important;
		}

		.submitbtn {
			width: 35vw !important;
			bottom: 30px !important;
			position: fixed !important;
			transform: translate(-50%, -50%) !important;
			bottom: 30px !important;
		}

		.submitbtndisabled {
			width: 35vw !important;
			position: fixed !important;
			transform: translate(-50%, -50%) !important;
			bottom: 30px !important;
		}

		.submitbtn_Member {
			width: 35vw !important;
			bottom: 30px !important;
			position: fixed !important;
			transform: translate(-50%, -50%) !important;
			bottom: 30px !important;
		}

		.submitbtndisabled_Member {
			width: 35vw !important;
			position: fixed !important;
			transform: translate(-50%, -50%) !important;
			bottom: 30px !important;
		}
	}

	.Welcomecontainer {
		.headerGrid {
			height: 100vh !important
		}

		.bodygrid {
			padding-right: 8vw !important;
			padding-left: 8vw !important;
			margin-top: 20vh !important;
		}

		.headingimgdiv {
			height: 350px !important;
			width: 400px !important;
		}

		.submitbtn {
			width: 35vw !important;
			bottom: 100px !important;
			transform: translate(25%, 25%) !important;
		}
	}

	.Profilecontainer {
		.bodygrid_profile {
			padding-right: 25vw !important;
			padding-left: 25vw !important;

		}

		.Correctbtn {
			width: 24vw !important;
			bottom: 10px !important;
			transform: translate(-0%, -50%) !important;
		}

		.EditProfilebtn {
			width: 24vw !important;
			bottom: 20px !important;
			transform: translate(-100%, -50%) !important;
		}
	}

	.EditProfilecontainer {
		.bodygrid_profileInformation {
			padding-right: 25vw !important;
			padding-left: 25vw !important;
			overflow-y: auto !important;
			max-height: calc(88vh - 140px) !important;

		}

		.aligncontainer {
			display: flex !important;
			justify-content: flex-end !important;
		}


		.textbox1, .textbox2 {
			width: 24vw !important;

			/* for textbox input this is for error border hence width is set*/
			.customInput {
				width: 24vw !important;
			}

		    .customInput1 {
				width: 24vw !important;
			}
		}

		.textbox1Streetaddress {
			width: 50vw !important;

			/* for textbox input this is for error border hence width is set*/
			.customInput {
				width: 50vw !important;
			}
		}

		.textbox1city {
			width: 16vw !important;

			/* for textbox input this is for error border hence width is set*/
			.customInput {
				width: 16vw !important;
			}
		}

		.textboxzipcode {
			width: 16.5vw !important;

			/* for textbox input this is for error border hence width is set*/
			.customInputzipcode {
				width: 16.5vw !important;
			}
		}

		.Drpdownselect {
			width: 16vw !important;
		}

		.nextbtn {
			width: 50vw !important;

		}


	}

	.Addresscontainer {
		.bodygrid_AdressInformation {
			padding-right: 25vw !important;
			padding-left: 25vw !important;
			overflow-y: auto !important;
			max-height: calc(88vh - 140px) !important;
		}

		.informationboxAddress {
			width: 50vw !important
		}

		.textbox1 {
			width: 45vw !important;

			.customInput {
				width: 45vw !important;
			}
		}

		.textboxzipcode {
			width: 22.5vw !important;

			/* for textbox input this is for error border hence width is set*/
			.customInput {
				width: 22.5vw !important;
			}
		}

		.errormsgzip {
			width: 22vw !important;
		}


		.Drpdownselect {
			width: 22vw !important;
			margin-top: 10px !important;
			border-radius: 10px !important;
			background-color: var(--defaultgrey) !important;
			border: transparent !important;
		}

		.nextbtn {
			width: 50vw !important;
		}
	}

	.Contactcontainer {
		.bodygrid_ContactInformation {
			padding-right: 25vw !important;
			padding-left: 25vw !important;
			overflow-y: auto !important;
			max-height: calc(88vh - 140px) !important;
		}

		.informationboxAddress {
			width: 50vw !important;
		}

		.inputlabel {
			text-align: left !important;
			font-family: var(--fontFamily) !important;
			font-size: var(--fontsizesmall) !important;
			color: var(--brandcolor) !important;
		}

		.textbox1 {
			width: 45.5vw !important;

			.customInput {
				width: 45.5vw !important;
			}
		}


		.textbox1error {
			width: 45.5vw !important;
		}

		.nextbtn {
			width: 50vw !important;
		}
	}

	.Preferredchannelcontainer {
		.bodygrid_Preferredchannel {
			padding-right: 25vw !important;
			padding-left: 25vw !important;
			overflow-y: auto !important;
			max-height: calc(88vh - 140px) !important;
		}

		.bodygrid_Preferredchannelnoemailphone {
			padding-right: 25vw !important;
			padding-left: 25vw !important;
		}

		.dynamicMessagebtn {
			width: 50vw !important;
			height: 70px !important;
		}

		.nextbtn {
			width: 50vw !important;
		}
	}

	.Preferredfrequencycontainer {
		.bodygrid_Preferredfrequency {
			padding-right: 25vw !important;
			padding-left: 25vw !important;
			overflow-y: auto !important;
			max-height: calc(88vh - 140px) !important;
		}

		.dynamicMessagebtn {
			width: 50vw !important;
			height: 80px !important
		}

		.nextbtn {
			width: 50vw !important;
		}
	}

	.LanguageConfirmation {
		.bodygrid_LanguageConfirmation {
			padding-right: 25vw !important;
			padding-left: 25vw !important;
			overflow-y: auto !important;
			max-height: calc(88vh - 140px) !important;
		}

		.Drpdownselect {
			width: 50vw !important;
		}

		.nextbtn {
			width: 50vw !important;
		}
	}

	.CommonLoader {
		.informationbox {
			text-align: left !important;
		}

		.informationbox2 {
			text-align: center !important;
		}

	}


	.fontPopupcontainer {
		width: 50vw !important;
	}
}

@media only screen and (min-height: 0px) and (max-height: 750px) {
	/* .messageSec {
		padding: 9rem 0 !important;
	} */
}


@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}