.parentEditprofile{
	font-family: var(--fontFamily) !important;
	font-size: var(--fontsizesmall) !important;

	
	p{
		margin: 0 !important;
	}
	
	
	.editPageheading{
		font-weight: bold;
		background-color: var(--defaultgrey);
		box-shadow: 2px 1px 2px 0.1px #3f3f3f ;
		font-size: var(--fontsizemedium);
		height: 40px !important;
	}
	
	.memberParent{
		text-align: left !important;
		/* padding-left: 40px !important;
		padding-right: 40px !important; */
	
	}
	
	.memberSubheading{
		font-weight: bold;
		font-size: var(--fontsizemedium);
		
	
	}
	
	.title{
		font-weight: bold;
		font-size: var(--fontsizesmall);
	
	}
	.memberInputlabel{
		/* font-weight: bold !important; */
		font-size: var(--fontsizesmall);
		color: var(--primary) !important;
		padding: 0 !important;
		padding-bottom: 5px !important;
	}
	
	
	.memberTextbox{
		padding: 0 !important;
		padding: 3px 0 !important;
	}
	.memberCusomInput .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
		border-color: transparent; /* Disable the outline color */
	}
	
	
	/* .memberTextbox .css-1u3bzj6-MuiFormControl-root-MuiTextField-root{
		width: 100%;
	} */
	
	.memberCusomInput,
	.memberDrpdownselect{
		width: 100% !important;
		background-color: var(--defaultgrey) !important;
		border-radius: 10px !important;
		padding: 0 !important;
		color: var(--primary);
		font-size: var(--fontsizesmall) !important;

	
	}
	.radioGroup .MuiFormControlLabel-label {
		font-size: var(--fontsizesmall) !important;
	
	}
	
	.memberCusomInput.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline,
	.memberDrpdownselect.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
		border-color: transparent !important;
	}
	
	.memberCusomInput.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.memberCusomInput.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline,
	.memberDrpdownselect.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline,
	.memberDrpdownselect.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline{
		border-color: transparent !important;
	}
	
	/* .memberCusomInput .MuiOutlinedInput-input,
	.memberDrpdownselect .MuiOutlinedInput-input{
		padding: 5px!important;
	} */
	
	
	.memberDrpdownselect{
		background-color: var(--defaultgrey) !important;
		width: 100% !important;
	}
	
	.memberErrormsg {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall)!important;
		color: red !important;
		text-align: left !important;
		margin-top: 5px !important;
	}
	
	
	.nextbtn {
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		width: 100% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		font-weight: bold !important;
	}
	
	.disablebtn{
		font-family: var(--fontFamily) !important;
		background-color: #989898 !important;
		width: 100% !important;
		height: 55px !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		text-transform: none !important;
		color: #000000 !important;
		font-weight: bold !important;
		pointer-events: none;
	}
	
	@media (max-width: 899px) {
		.editPageheading{
			background: transparent !important;
			box-shadow: none !important;
			margin-top: 20px !important;
		}
	}

}