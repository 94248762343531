.SurveyEmptyTemplatecontainer {
	.Survey_bodygrid_Template1 {
		padding-right: 25vw !important;
		padding-left: 25vw !important;
		padding-top: 25px;
	}
	.Heading {
		color: var(--brandcolor) !important;
		font-size: var(--fontsizemedium) !important;
		font-family: var(--fontFamily) !important;
		font-weight: bold !important;
	}

	.Nextbtn{
		font-family: var(--fontFamily) !important;
		background-color: var(--btncolor) !important;
		height: 55px !important;
		width: 100% !important;
		border-radius: 10px !important;
		font-size: var(--fontsizemedium) !important;
		bottom: 10px !important;
		text-transform: none !important;
		font-weight: bold !important;
	}


}

@media only screen and (min-width: 0px) and (max-width: 1269px) {
	.SurveyEmptyTemplatecontainer {
		.Survey_bodygrid_Template1 {
			padding-right: 8vw !important;
			padding-left: 8vw !important;
		}
 }
}