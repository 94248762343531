.MemberSnapshot-container {
	margin-top: 15%;
	height: 80vh;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	border: none !important;
	border-radius: 12px;
	overflow-x: hidden;
	z-index: 1;

	.modal-container {
		box-sizing: border-box;
		width: 100%;
		box-sizing: "border-box";
		z-index: 1 !important;

	}

	.grid-container {
		width: 100%;
		margin: -30px auto 0px auto !important;
		padding-bottom: 20px;
		z-index: 1;
	}
	.iconsbtn{
		margin-top: 5px;
		margin-right: 5px;
		width: 50px;
		height:50px;
		cursor: pointer;
	}

	.iconsbtndisabled{
		margin-top: 5px;
		margin-right: 5px;
		width: 50px;
		height:50px;
		pointer-events: none;
        opacity:0.1;
	}


	.data-containers {
		width: 100%;
		border-radius: 12px;
		border: 1px solid rgba(216, 222, 228, 1);
		margin: 0 auto 0 auto;
		margin-top: 10px;
		background-color: rgba(255, 255, 255, 1);
	}

	.container-1 {
		border: none;
		border-radius: 0px;
		display: flex;
		justify-content: flex-end;
		background-color: rgba(237, 237, 237, 1);
		padding-top: 10px;
		position: sticky;
		top:-33px;
	}

	.contact-btn {
		width: 50px;
		height: 50px;
		border-radius: 25px;
		margin-right: 15px;
		background-color: rgba(33, 148, 242, 1);
		border: 2px solid white;
		box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
		cursor: pointer;
	}

	/*  */

	.stickydiv{
		height: 225px;
		
		padding-bottom: 5px;
		z-index: 999;
		position: sticky;
		top: 30px;
	}
	/*  */

	.container-2 {
		padding-bottom: 20px;
		padding-top: 10px;
		
		
	}

	.container-2-infotext {
		width: 100%;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 0.02em;
		text-align: left;
		color: rgba(38, 38, 38, 1);
		word-wrap: normal;
		line-height: 2;
	}

	.MuiOutlinedInput-notchedOutline {
		border: none !important;
	}

	.container-2-form-text-field {
		width: 82% !important;
		border-radius: 12px !important;
		font-size: 14px;
		border: 2px solid rgba(33, 148, 241, 0.4)
			/* height: 50px; */
	}

	.container-2-form-submit-btn {
		width: 13%;
		font-family: Inter;
		font-size: 14px;
		font-weight: 700;
		line-height: 21.78px;
		height: 57px;
		text-align: left;
		background-color: rgba(33, 148, 242, 1);
		border-radius: 12px;
		color: rgba(255, 255, 255, 1);
		padding: 16px;
		margin-left: 2%;
	}

	.personal-info-box {
		width: 100%;
	}

	.personal-info-banner {
		width: 27%;
		padding: 5px;
		margin-left: -11.5px;
		background-color: rgba(0, 69, 140, 1);
		font-size: 16px;
		font-weight: 700;
		line-height: 24.2px;
		text-align: left;
		color: rgba(255, 255, 255, 1);
		text-align: center;
	}

	.persoanl-info-data-grid {
		width: 95%;
		margin: 0 auto 0 auto;
	}

	.pers-info-cell-variable {
		font-family: Inter;
		font-size: 14px;
		font-weight: 600;
		line-height: 19.36px;
		letter-spacing: 0.01em;
		text-align: left;
		color: rgb(114, 117, 119)
	}

	.pers-info-cell-data {
		font-family: Inter;
		font-size: 14px;
		font-weight: 700;
		line-height: 21.78px;
		letter-spacing: 0.01em;
		text-align: left;
		color: rgba(0, 0, 0, 1);
		word-wrap: break-word;
	}

	/* campaign info grid design */

	.campaign-info-box {
		width: 100%;
		padding-bottom: 20px;
	}

	.campaign-info-banner {
		width: 27%;
		padding: 5px;
		margin-left: -11.5px;
		background-color: rgba(0, 69, 140, 1);
		font-size: 16px;
		font-weight: 700;
		line-height: 24.2px;
		text-align: left;
		color: rgba(255, 255, 255, 1);
		text-align: center;
	}

	.campaign-info-grid-1 {
		width: 93%;
		margin: 0 auto 0 auto;
		border: 0.5px solid black;
		margin-top: 20px;
	}

	.campaign-info-grid-1-cell {
		border: 0.5px solid black;
		padding: 10px 0px;
		text-align: center;
	}

	.campaign-grid-1-cell-data {
		font-size: 14px;
		font-weight: 700;
	}

	.campaign-info-grid-2 {
		width: 93%;
		margin: 0 auto 0 auto;
		margin-top: 20px;
	}

	.campaign-info-grid-2-cell {
		border: 2px solid black;
		padding: 10px 0px;
		text-align: center;
		width: 49%;
	}

	.campaign-grid-2-cell-data {
		font-size: 14px;
		font-weight: 700;
	}

	/* new changes in styles */

    .no-campaign-info-content{
		width: 100%;
		text-align: center;
        margin-top: 35px;
		padding-bottom: 20px;
        font-weight: 600;
        font-size: 16px;
    }
    
}