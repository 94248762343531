.timepickerForm fieldset.MuiOutlinedInput-notchedOutline,
.timepickerForm :hover fieldset.MuiOutlinedInput-notchedOutline,
.timepickerForm .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
	border-color: transparent;
}

.timepickerForm{
	background: var(--lightgrey);
	border-radius: 10px;
}

@media (max-width:1299px){
	.css-1t1j96h-MuiPaper-root-MuiDialog-paper{
		margin-left: 32px !important;
	}
}