body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
	overscroll-behavior: none;
	-ms-overscroll-behavior: none; /* For Internet Explorer */
	-webkit-overscroll-behavior: none;
  }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #000000;
  --secondary: #FFFFFF;
 --brandcolor: #0C0C47;
 --tertiary: #00E0BE;
 --btncolor : #B30404;
 --secondarybtncolor: #98A2B3;
  --defaultgrey:#E1E1E1;
  --darkgrey: #5a5a5a;
  --grey2:#98A2B3;
  --lightgrey: #DADADA;
  --lightblue: #DEE7FF;
  --fontFamily: 'Inter';
  --fontsizesmall:16px;
  --fontsizemedium:18px;
  --fontsizelarge:20px;
  --Agent_bgcolor1: #004791;
  --Agent_textcolor2: #00458C;
  --Agent_bgcolor3 : #2194F2;
  --Agent_greycolor : #EDEDED;
  --Agent_outlinecolor : #BCC5CC;
  --Agent_fontsizeextrasmall : 14px;
}

