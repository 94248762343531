.planParent{
	font-family: var(--fontFamily) !important;
	font-size: var(--fontsizesmall) !important;
	
	p{
		margin: 0 !important;
	}
	
	.planPageheading{
		font-weight: bold;
		background-color: var(--defaultgrey);
		box-shadow: 2px 1px 2px 0.1px #3f3f3f ;
		font-size: var(--fontsizemedium) ;
		height: 40px !important;
	}

	.planInfoLeftalign{
		text-align: left;
	}
	
	
	.accountPlanParent{
		padding : 30px 0 0 25px !important;
		word-wrap : "break-word";
	}
	.memberInputlabel{
		font-weight: bold !important;
		font-size: var(--fontsizesmall);
		color: var(--primary) !important;
		padding: 0 !important;
		padding-bottom: 5px !important;
	}

	@media (max-width: 899px) {
		.planPageheading{
			background: transparent !important;
			box-shadow: none !important;
			margin-top: 10px !important;
		}
	}
}