.GlobalSucessPopupcontainer{
	position: absolute !important;
	background-color: white !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	outline: none !important;
	top: 50% !important;
	border-radius: 5px !important;
	width: 500px !important;
	padding-left: 10px !important;
	padding-right: 10px !important;

	.CommonLoadermaingrid {
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 45px;

	}

	.GridIcon {
		text-align: right;
		cursor: pointer;
	}

	.textlabel1 {
		font-size: var(--fontsizelarge) !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
		line-height: 30px;

	}
	.okbtn{
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--Agent_bgcolor1) !important;
		color: var(--secondary);
		width: 100px !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
	}


}