.text-btn-container{
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 5px 0px;
    justify-content: space-between;
    margin : 20px auto 0 auto;
}
.child-modal-banner{
    margin-top: 0px;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    text-align: left;
}
.input-text{
    width: 85%;
    border: 1px solid rgba(169, 169, 169, 1) !important;
    border-radius: 4px;
    font-size: var(--fontsizesmall);
}
.update-btn{
    width: 13%;
    margin-left: 2%;
    padding: 8px 0px !important;
    background-color: rgb(0, 71, 145);
    text-transform: capitalize;
}
.close-child{
    position: absolute;
    right: 5px;
    top: 5px;
    border: none;
    background-color: white;
    cursor: pointer;
}
#child-modal-text-id{
    padding:8px!important;
}