.CampaignHistoryPopupcontainer{
	position: absolute !important;
	background-color: white !important;
	left: 50% !important;
	transform: translate(-50%, -50%) !important;
	outline: none !important;
	top: 50% !important;
	border-radius: 5px !important;
	width: 95% !important;
	height:95% !important;
	padding-left: 10px !important;
	padding-right: 10px !important;
	overflow-y: auto;

	.CommonLoadermaingrid {
		padding-left: 25px;
		padding-right: 25px;
		padding-bottom: 45px;

	}

	.GridIcon {
		text-align: right;
		cursor: pointer;
	}

	.textlabel1 {
		font-size: var(--fontsizelarge) !important;
		font-family: var(--fontFamily) !important;
		color: var(--primary) !important;
		line-height: 30px;

	}
}
/*  */

*{
    font-family: 'Inter';
    box-sizing: border-box;
}
.popup-container{
    width: 95%;
    margin: -70px auto 0 auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding:10px 20px;
}
.campaign-history-banner{
    font-size: 22px;
    font-weight: bold;
    color: #292929;
}
.data-containers{
    width:98%;
    margin: 15px 15px;
    /* padding:20px; */
    border: 1px solid #BCC5CC;
    border-radius: 3px;
}
.data-container-1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-right: 30px;
}
.bar-chart-1-banner{
    font-family: Inter;
    font-size: 18px;
    font-weight: 700;
    line-height: 20.57px;
    text-align: left;
    color: rgba(41, 41, 41, 1);
    align-self: flex-start;
    padding-left: 3%;
}
.data-container-2{
    max-height: 70vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding-bottom: 20px;
    margin-top:20px;
}
.data-container-banner{
    font-size: 18px;
    font-weight: bold;
    color: #292929;
    align-self: flex-start;
    padding-left: 3%;
}
.table-headings{
    font-size: 15px;
    font-weight: bold;
    text-align: center;  
    word-wrap: break-word;
}
.data-table{
    border: 1px solid #BCC5CC;
}
.table-data{
    font-size: 14px;
    word-wrap: break-word;
}
.table-data-all{
    font-size: 14px;
    text-align: center;
    padding: 5px;
    font-weight: 600;
}
.table-grid{
    width: 98%;
    margin: 0 auto 0 auto;
}
.table-data-grid{
    width: 98%;
    margin: 0 auto 0 auto;
}
.table-data-col-1{
    width:max-content;
    padding:5px 10px;
    margin-left: 10px;
    border-radius: 14px;
    text-decoration: underline;
}
.box-3{
    width: 98%;
    padding: 0px;
    margin: 5px auto 10px auto;
    display: flex;
    justify-content: space-between;
}
.campaign-stats-charts{
    width: 90%;
    height: 52vh;
    padding: 5px;
    margin: 0 auto 0 auto;
}
.data-container-4{
    margin: 15px auto 0 auto;
    padding: 10px;
    padding-bottom: 25px;
    border: 1px solid #BCC5CC;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
}
.box-3-campaign-stats-banner{
    font-size: 18px;
    font-weight: bold;
    padding-left: 2.5%;
}
.campaign-stats-data-grid{
    width: 99%;
    align-self: center;
}
.campaign-stats-data-cell{
    width: 100%;
    height: 25vh;
    padding: 30px 20px;
    border: 1px solid #BCC5CC;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.campaign-stat-data-point{
    font-family: Inter;
    font-size: 24px;
    font-weight: 500;
    line-height: 29.05px;
    letter-spacing: 0.01em;
    text-align: left;
    text-align: center;
}
.campaign-stat-data-variable{
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.94px;
    letter-spacing: 0.01em;
    text-align: center;
    margin-top: -20px;
}

/*  */
/* For Webkit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
    width: 8px; /* Adjust the width as needed */
    height: 8px; /* Adjust the height as needed for horizontal scrollbars */
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #888; /* Scrollbar color */
    border-radius: 3px; /* Set the border radius to 3px */
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Scrollbar color on hover */
  }
  
  /* Remove the default scrollbar arrows */
  ::-webkit-scrollbar-button {
    display: none; /* Hides the default arrows */
  }
  
  /* For Firefox */
  * {
    scrollbar-width: thin;
    scrollbar-color: #888 #fff; /* Scrollbar color and background color */
    scrollbar-arrow-color: transparent; /* This may not work for all Firefox versions */
  }
  
  


