.HeaderContainerMember {
	.Headercontainercol1 {
		text-align: left;
		margin-left: -30px;
		z-index: 1;
	}

	.togglebtnHamburger {
		background-color: #ffffff;
		outline: none;
		border: 1px solid white;
	}

	.HamburgerIconimg {
		width: 15px;
		height: 15px;
		object-fit: cover;
	}

	.headingimgdiv_profile {
		height: 50px !important;
		width: 200px !important;
		margin-left: 25px;
	}

	.headingimgdivmobile {
		height: 50px !important;
		width: 200px !important;
	}

	.headingimgGrid_mobile {
		display: grid;
		place-items: center;
	}

	.headingimgdiv_profileicon {
		height: 40px !important;
		width: 40px !important;
		border-radius: 100%;
	}

	.contentGrid {
		justify-content: center;
		display: flex;
		flex-direction: column;
	}

	.textlabel {
		font-Family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall) !important;
		color: var(--secondary) !important;
		text-align: right;
	}

	.textlabel1 {
		font-Family: var(--fontFamily) !important;
		font-size: 14px !important;
		color: var(--secondary) !important;
		text-align: right;
	}

	.headingcol1_profile {
		display: grid !important;
		place-items: center !important;
	}

	.headingcol2_profile {
		display: grid !important;
		place-items: end !important;
	}

	.headingimgdiv2_profile {
		height: 25px !important;
		width: 50px !important;
		margin-right: 25px;
	}

	.Profileimage {
		width: 100%;
		height: 100%;
		object-fit: contain;
		margin-left: 10px;
		cursor: pointer;
	}
}

@media only screen and (min-width: 0px) and (max-width: 1269px) {

	.HeaderContainerMember {
		/* .sidenavmobile {
			height: calc(100vh - 80px);
            position: fixed;
            z-index: 1;
            margin-top: 70px;
			padding-bottom: 20px;
            overflow-y: auto;
            width: 100vw;
            background-color: var(--secondary) !important;
		} */


		.sidenavmobile {
            z-index: 1;
            left: 0;
            padding-bottom: 20px;
            width: 100vw;
            background-color: var(--secondary) !important;
        }

		.sidenavmobileclose{
			display: none;

		}


		.sidenavmobile a {
			text-decoration: none;
			font-size: 22px;
			color: #fff;
		}

		.welcometext {
			font-Family: var(--fontFamily) !important;
			font-size: var(--fontsizelarge) !important;
			text-align: center;
			color: var(--brandcolor) !important;
			margin-top: 70px;
		}

		.gridcontainermobile {
			padding-left: 25px;
			padding-right: 25px;
		}

		.buttondivmobile {
			font-Family: var(--fontFamily) !important;
			font-size: var(--fontsizesmall) !important;
			min-height: 70px;
			border: 1px solid var(--brandcolor);
			border-radius: 10px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding-left: 15px;
			padding-right: 15px;
			color: var(--brandcolor) !important;
			font-weight: bold;
			margin-bottom: 15px;
		}

		.itemlogomobile {
			position: absolute;
			left: 40px;
			width: 21px;
			height: 28px;
		}

		.logoutbtn {
			background: var(--secondary) !important;
			width: 100%;
			height: 50px;
			border-radius: 10px;
			font-size: var(--fontsizemedium) !important;
			font-family: var(--fontFamily) !important;
			color: var(--primary) !important;
			text-transform: none !important;
			font-weight: bold;
		}
		.btndiv{
			font-size: var(--fontsizemedium) !important;
			font-family: var(--fontFamily) !important;
			color: var(--primary) !important;
			text-transform: none !important;
			font-weight: bold;
			display: flex;
            align-items: center;
            justify-content: center;
		}

		.linediv{
			border-bottom: 1px solid  var(--primary);
		}
	}
}