.notifications-page{
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
	padding-top: 55px;

    .notification-page-header{
        width: 93%;
        margin: 20px auto 0 auto;
        padding:5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .navigate-back{
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(0, 71, 145, 1);
        text-transform: capitalize;
        padding: 0% !important;
    }
    .campaign-name{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .campaign-banner{
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(38, 38, 38, 1)
    }
    .navigate-resolved-unresolved-btn{
        font-size: 14px;
        font-weight: 700;
        text-align: left;
        color: rgba(0, 69, 140, 1);
        text-transform: capitalize;
        text-decoration: underline;
    }
    .unresolved-notifications-container{
        width: 95%;
        padding: 5px;
        height: 74vh;
        /* overflow-y: auto; */
        margin: -10px auto 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
    .unresolved-notification-item{
        width: 95%;
        border-radius: 12px;
        border: 2px solid rgba(217, 217, 217, 1);
        margin: 10px auto 0 auto;
        margin-top: 20px;
        background-color: rgba(255, 255, 255, 1);
        padding: 0px 20px 20px 20px;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .item-infotext{
        font-size: 14px;
        width: 85%;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(38, 38, 38, 1);
        word-wrap: normal;
        padding: 4px 0px;
        padding-left: 3px;
        line-height: 2;
    }
    .unresolved-item-form-text-field{
        width: 85% !important;
        border-radius: 10px !important;
        padding: 0% !important;
        font-size: 14px;
        border : 1px solid rgba(141, 140, 140, 0.6);
    }
    .MuiOutlinedInput-notchedOutline{
        border: none !important;
        }
    .MuiInputBase-input{
        padding: 10px !important;
    }
    .unresolved-item-form-submit-btn{
        width: 11%;
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 21.78px;
        height: 44px;
        text-align: left;
        background-color: rgba(33, 148, 242, 1);
        border-radius: 12px;
        color: rgba(255, 255, 255, 1);
        padding: 15px;
        margin-left: 3%;
        text-transform: capitalize;
    }
    .item-date-raised{
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.02em;
        text-align: start;
        color: grey;
        padding-left: 3px;
        margin-top: -15px;
    }

    /* resolved page design */

    .resolved-notifications-container{
        width: 97%;
        padding: 5px 5px 0px 5px;
        height: 74vh;
        overflow-y: auto;
        margin: 0 auto 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .resolved-notification-item{
        width: 98%;
        border-radius: 10px;
        border: 2px solid rgba(217, 217, 217, 1);
        margin: 10px auto 0 auto;
        margin-top: 15px;
        
        background-color: rgba(255, 255, 255, 1);
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.25);
    }
    .resolved-item-infotext{
        width: 75%;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.02em;
        text-align: left;
        color: rgba(38, 38, 38, 1);
        word-wrap: normal;
        line-height: 2;
        padding-left: 2.5%;
        padding-top: 3px;
        padding-bottom: 1px;
        margin: 5px 0 5px 0;
    }
    .resolved-item-date-raised{
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: start;
        color: grey;
        padding-left: 2.5%;
        margin-top: -5px;
    }
    .resolved-item-data{
        display: flex;
        flex-direction: column;
        width: 95%;
        margin: -9px auto 0 auto;
        align-items: flex-start;
        padding: 5px 0px;
    }
    .resolved-item-data-text{
        font-size: 16px;
        width: 80%;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: left;
        word-wrap: break-word;
        margin-top: 10px;
        margin-bottom: 6px;
    }
    .resolved-item-date-author{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: -5px;
        /* justify-content: space-evenly; */
    }
    .item-author{
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 26.63px;
        text-align: left;
        color: grey;
        margin-top: 3px;
        margin-bottom: 0px;
    }
    .item-date-time{
        font-family: Inter;
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        letter-spacing: 0.02em;
        text-align: center;
        color: grey;
        margin-top: -3px;
        margin-bottom: 3px;
        margin-left: 1px;
    }
    .member-name-link{
        text-decoration: none;
        border-bottom: 1px solid blue;
        color: blue;
        cursor: pointer;
    }
    .no-content-page{
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        padding-top: 150px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .no-content-message{
        margin-top: 25px;
        padding-left: 2%;
        
    }
    .dropdown{
        position: absolute;
        right: 5px;
        top: 25px;
        cursor: pointer;
        
    }
    .dropdown-resolved{
        position: absolute;
        right: 20px;
        top: 20px;
        cursor: pointer;
        
    }

    
    
}
