/* CountDown.css */

  .heading {
	font-size: 18px; /* Slightly larger for better readability */
	margin-bottom: 10px;
	color:black;
  }
  
  .time-container {
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 10px; /* Space between time blocks */
  }
  
  .time {
	display: flex;
	flex-direction: column;
	align-items: center;
  }
  
  .time div {
	font-size: 38px; /* Larger font size for better visibility */
	color: #349e02;
	margin-bottom: 5px;
	font-weight: bold;
  }
  
  .time p {
	font-size: 18px;
	color:black;
	margin: 0;
  }
  
  .separator {
    font-size: 28px;
    color: #349e02;
    margin-right: 10px;
    margin-top: -30px;
    font-weight: bold;
}