.file-upload-container {
	width: 100%;
	box-sizing: border-box;
}

.file-upload-modal-container {
	border: none;
	border-radius: 16px;
	/* height: 45vh; */
	.close-file-upload-btn {
		position: absolute;
		top: 5px;
		right: -10px;
	}

	.inner-file-upload-container {

		width: 95%;

		height: 300px !important;

		border: 2.5px dashed rgba(33, 148, 242, 1);

		border-radius: 10px;

		margin: 35px auto 0 auto;

		display: flex;

		align-items: center;

		justify-content: center;
		padding-bottom: 10px;

	}

	.all-btns-container {

		/* width: 60%;
		height: 55%; */

		padding: 5px;

		display: flex;

		flex-direction: column;

		align-items: center;

		justify-content: center;



	}



	.drag-drop-info-banner {

		font-family: Roboto;

		font-size: 30px;

		font-weight: 400;

		text-align: left;

		color: rgba(33, 148, 242, 1);

		margin-top: 0%;

		margin-bottom: 0%;

		padding: 5px;

	}

	.or-info-banner {

		font-family: Roboto;

		font-size: 30px;

		font-weight: 400;

		text-align: center;

		color: rgba(33, 148, 242, 1);

		margin-top: 0%;

		margin-bottom: 0%;

		padding: 5px;

	}

	.browse-upload-btn {

		background-color: rgba(33, 148, 242, 1);

		border-radius: 10px;

		font-family: Roboto;

		font-size: 16px;

		font-weight: 400;

		text-align: left;

		color: rgba(255, 255, 255, 1);

		padding: 10px 40px;

		height: 50px;

		margin-top: 10px;

		margin-bottom: 0%;

		text-transform: capitalize;

		letter-spacing: 1px;

		word-spacing: 4px;

	}

	.browse-upload-btn:hover {

		background-color: rgba(33, 148, 242, 1);

	}

	.submit-file-contianer {

		display: flex;

		align-items: center;

		justify-content: space-around;

		/* background-color: rgba(216, 222, 228, 1); */

		width: 60%;

		margin: 15px auto 0 auto;

		border-radius: 12px;



	}

	.file-name {

		font-size: 14px;

		font-family: Verdana, Geneva, Tahoma, sans-serif !important;

		font-weight: 500;

		padding-right: 0px;

		margin-left: 10px;
		;

	}

	.file-submit-btn {

		border: none;

		background: white;

		font-size: 16px;

		border: 1px solid rgba(33, 148, 242, 1);

		font-weight: 600;

		cursor: pointer;

		text-transform: capitalize;

		height: 40px !important;

		padding: 2px 20px;

	}

	.file-submit-btn:hover {

		background-color: rgba(33, 148, 242, 1);

		color: white
	}

	.download-file {

		border: 1px solid rgba(33, 148, 242, 1);

		border-radius: 4px;

		position: absolute;

		right: 45px;

		top: 15px;

	}

	.hyper-link {

		text-decoration: none;

		color: rgba(33, 148, 242, 1);



	}


}