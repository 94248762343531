.CampaignDetailsContainer {
	.bodygrid_CampaignDetails {
		padding-top: 75px;
		padding-left: 25px;
		padding-right: 25px;
	}

	.topgridlayout {
		display: flex;
		justify-content: end;
		gap: 10px;
		white-space: nowrap;
	}


	.CampaignHistorynbtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2) !important;
		width: 200px !important;
		height: 45px !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		border: 1px solid var(--Agent_textcolor2);
		flex-shrink: 0;
	}

	.Newcampaignbtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: var(--Agent_bgcolor1) !important;
		width: 200px !important;
		height: 45px !important;
		border-radius: 5px !important;
		font-size: 14px;
		text-transform: none !important;
		font-weight: bold !important;
		flex-shrink: 0;

	}

	.overlayDiv {
		padding-left: 10px;
		padding-bottom: 10px;
		position: absolute;
		top: 230px;
		z-index: 1000;
		background-color: #F6F6F6;
		border-radius: 5px;
		box-shadow: 0 8px 8px rgb(0 0 0 / 10%);
		width: 450px;
		min-height: 200px;
		max-height: calc(100vh - 280px) !important;
		text-align: left;
		overflow: auto;
		padding-bottom: 20px;
		border-radius: 10px;
	}

	.overlay_div_Itemtext_active {
		cursor: pointer;
		margin-top: 10px;
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		padding: 10px;
		background-color: #D9D9D9;
		line-height: 30px;
		;
	}

	.overlay_div_Itemtext {
		cursor: pointer;
		margin-top: 10px;
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		padding: 10px;
		line-height: 30px;
	}

	.tabmaindiv {
		display: flex;
		align-items: center;
		margin-top: 20px;

	}

	.tabboxdiv {
		display: flex;
		overflow-x: hidden;
		white-space: nowrap;
		flex: 1;
		margin-left: 15px;
	}

	.tabdivtext {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		margin-left: 15px;
		margin-right: 15px !important;
		white-space: nowrap;
		overflow: hidden;
		/* text-overflow: ellipsis; */
	}

	.Published_tab {
		border: 1px solid #ccc;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
		transition: 'all 0.3s ease';
		margin-right: 2px;
		background-color: var(--Agent_bgcolor1) !important;
		color: var(--secondary);
		display: flex;
		align-items: center;
		justify-content: left;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.Draft_tab {
		border: 1px solid #ccc;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
		transition: 'all 0.3s ease';
		margin-right: 2px;
		background-color: var(--Agent_bgcolor3) !important;
		color: var(--secondary);
		display: flex;
		align-items: center;
		justify-content: left;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;
	}

	.Tab_selected {
		margin-top: 0px;
		min-width: 300px;
		max-width: 300px;
		height: 60px;
	}

	.Tab_unselected {
		margin-top: 20px;
		width: 300px;
		height: 40px;
	}

	/* .Tab_selected {

		border: 1px solid #ccc;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
		transition: 'all 0.3s ease';
		width: 300px;
		min-height: 60px;
		margin-top: 0px;
		margin-right: 8px;
		background-color: var(--Agent_bgcolor3) !important;
		color: var(--secondary);
		display: flex;
		align-items: center;
		justify-content: left;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

	}

	.Tab_unselected {
		border: 1px solid #ccc;
		white-space: nowrap;
		overflow: hidden;
		cursor: pointer;
		transition: 'all 0.3s ease';
		width: 300px;
		min-height: 40px;
		margin-top: 20px;
		margin-right: 8px;
		background-color: white;
		color: black;
		display: flex;
		align-items: center;
		justify-content: left;
		border-top-left-radius: 10px;
		border-top-right-radius: 10px;

	} */

	.information-css{
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		color: red;
		text-align: right;
	}

	.text1_css {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		color: var(--primary);
		font-weight: bold;

	}

	.accordion_header {
		font-size: 14px;
		background-color: var(--Agent_greycolor) !important;
	}

	.label_text {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--primary);
		margin-bottom: 5px;
	}

	.label_text1 {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--primary);
		margin-bottom: 5px;
		font-weight: bold;
	}

	.togglebuttonouter_div {
		height: 50px;
		width: 210px;
		background-color: var(--Agent_bgcolor1) !important;
		border: 1px solid #ccc;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.togglebtn_selected {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2);
		border: 1px solid var(--secondary) !important;
		border-radius: 100px !important;
		height: 40px;
		width: 100px;
		text-transform: none !important;

	}

	.togglebtn_default {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		/* background-color: var(--Agent_bgcolor1) !important;
		border:1px solid var(--Agent_bgcolor1) !important;  */
		color: var(--secondary) !important;
		border-radius: 100px !important;
		height: 40px;
		width: 100px;
		text-transform: none !important;
	}

	.togglebtn_selected:hover {
		background-color: var(--secondary) !important;
	}

	.togglebtn_default:hover {
		background-color: var(--Agent_bgcolor1) !important;
	}

	.grid-CampaignType {
		text-align: left;
		display: flex;
		align-items: center;
	}

	.grid-left {
		text-align: left;
	}



	.dropdown-header {
		cursor: pointer;
		border: 1px solid var(--Agent_outlinecolor);
		background-color: #f9f9f9;
		width: 200px;
		width: 103%;
		height: 45px;
		display: flex;
		align-items: center;
		border-radius: 5px;

	}

	.dropdown-headertext {
		color: var(--primary) !important;
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall) !important;
		padding-left: 10px;
		width: 100%;
	}

	.dropdown-list {
		list-style-type: none;
		padding: 0;
		margin: 0;
		position: absolute;
		background-color: #fff;
		border: 1px solid #ccc;
		z-index: 1;
		width: 128px;
	}

	.dropdown-list li {
		padding-left: 7px;
		padding-right: 7px;
		padding-top: 9px;
		padding-bottom: 9px;
		cursor: pointer;
		color: var(--primary) !important;
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizesmall) !important;
	}

	.dropdown-list li:hover {
		background-color: #f1f1f1;
	}

	.keyboard_arrow_down {
		float: right;
		padding-right: 10px;
		padding-top: 5px;
	}

	.Drpdownselectsurvey_promotion {
		width: 100% !important;
		height: 45px;
		border: 1px solid var(--Agent_outlinecolor);
		border-radius: 5px;

		.MuiSelect-select {
			color: var(--primary) !important;
			font-family: var(--fontFamily) !important;
			/* font-size: var(--Agent_fontsizeextrasmall) !important; */
			font-size: 10px !important;
			margin-top: -5px;
			/* width: 85%; */
		}
	}

	.Drpdownselect {
		width: 100% !important;
		font-size: 14px;
		height: 45px;
		border-radius: 5px;
		border: 1px solid var(--Agent_outlinecolor);
		background-color: white;

		.MuiSelect-select {
			color: var(--primary) !important;
			font-family: var(--fontFamily) !important;
			font-size: var(--Agent_fontsizeextrasmall) !important;
			margin-top: -5px;

		}

	}


	.Previewbtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		margin-top: 21px;
		border: 1px solid var(--Agent_textcolor2);
	}

	.DisablePreviwbtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: #ffffff !important;
		color: #c5d3e1;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		margin-top: 21px;
		border: 1px solid #b8cfe6;
		font-weight: bold !important;
		pointer-events: none;
		cursor: not-allowed;
	}

	.Lineimage {
		margin-top: 21px;
	}

	.customDateTimePicker {
		width: 100%;
		height: 45px;
		margin-top: 21px;
		border: 1px solid var(--Agent_outlinecolor);
		border-radius: 5px;
	}

	.customDatePicker {
		border: 1px solid var(--Agent_outlinecolor);
		width: 100%;
		height: 45px;
		background-color: white;
		border-radius: 5px;
	}

	.customDateTimePicker input {
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		padding-left: 15px !important;
		margin-top: -5px;
	}

	.customDatePicker input {
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		padding-left: 15px !important;
		margin-top: -5px;
	}

	.textlabel_description_outerdiv {
		height: 100px;
		background-color: #D3E7FF;
	}

	.textlabel_description_outerdivscroll {
		height: 90px;
		word-wrap: break-word;
		overflow: auto;
	}

	.textlabel_description_outerdiv_campaigntracker {
		background-color: #D3E7FF;
		margin-bottom: 15px;
	}


	.textlabel_description {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		text-align: left;
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 10px;
	}

	.textlabel_CriticallyLevel_outerdiv {
		background-color: #D3E7FF;
		height: 100px;
	}

	.textlabel_CriticallyLevel {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		padding-top: 15px;
		padding-bottom: 10px;
		font-weight: bold;

	}

	.CriticallyLevel_inputbox {
		border: 1px solid var(--Agent_outlinecolor);
		width: 50px;
		height: 30px;
		border-radius: 10px;
		background-color: white;
	}

	.CriticallyLevel_inputbox input {
		margin-top: -12px;
		margin-left: 5px;
	}

	.toggleAutomaticReminder_div {
		background-color: var(--Agent_bgcolor1) !important;
		border: 1px solid #ccc;
		border-radius: 100px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 45px;
		width: 15vw;

	}

	.togglebtnAutomaticReminder_selected {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2);
		border: 1px solid var(--secondary) !important;
		border-radius: 100px !important;
		height: 40px;
		width: 7.3vw;
		text-transform: none !important;

	}

	.togglebtnAutomaticReminder_default {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--secondary) !important;
		border-radius: 100px !important;
		height: 40px;
		width: 7.3vw;
		text-transform: none !important;
	}


	.AutoResponsebtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		margin-top: 28px;
		border: 1px solid var(--Agent_textcolor2);
	}


	.disable_AutoResponsebtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: #e1e1e1 !important;
		/* color: #c5d3e1; */
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		margin-top: 28px;
		pointer-events: none;
		cursor: not-allowed;
	}

	.ApplyFiltersebtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		margin-top: 21px;
		border: 1px solid var(--Agent_textcolor2);
	}

	.ClearFiltersbtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--Agent_textcolor2) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		margin-top: 21px;
	}

	.disable_ApplyFiltersebtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: #e1e1e1 !important;
		color: #c5d3e1;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		margin-top: 21px;
		pointer-events: none;
		cursor: not-allowed;

	}

	.filter_GridContainer {
		background-color: #e7f2ff;
		margin-top: 15px;
		margin-left: 2px;
		margin-right: 5px;
		padding-bottom: 15px;
		padding-left: 5px;
		padding-right: 5px;

	}

	.filter_GridContainer2 {
		padding-left: 15px;
		padding-right: 15px;
	}


	.switchlabel .MuiFormControlLabel-label {
		text-align: left;
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		font-weight: bold;
		color: var(--primary) !important;
	}


	.switchlabel .MuiSwitch-switchBase {
		/* Default background color of the switch */
	}

	.switchlabel .MuiSwitch-switchBase.Mui-checked {
		/* Background color when switch is checked */
		color: var(--Agent_bgcolor1) !important;
	}

	.switchlabel .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
		background-color: var(--Agent_bgcolor1) !important;
	}

	.textfield-container {
		position: relative;
		display: block;
	}

	.searchinput {
		width: 100%;
		height: 45px;
		border: 10px;
		border: 1px solid var(--Agent_outlinecolor);
		border-radius: 5px;
	}

	.searchinput input {
		font-weight: bold !important;
		font-Family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall) !important;
		width: 80%;
		margin-top: -3px;
	}

	.searchicon {
		position: absolute;
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.Search_btn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		border: 1px solid var(--Agent_textcolor2);
	}

	.columnclassname {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.header-content {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		font-weight: bold;
		white-space: normal;
		word-wrap: break-word;
		overflow: hidden;
		text-overflow: ellipsis;
		text-align: left;
		margin-left: 15px;

	}

	.cell-content {
		white-space: normal !important;
		word-wrap: break-word !important;
		height: 50px;
		display: flex;
		align-items: center;
		line-height: 18px !important;
		margin-left: 15px;

	}

	.cell-content_type1 {
		white-space: normal !important;
		word-wrap: break-word !important;
		height: 50px;
		display: grid;
		align-items: center;
		line-height: 18px !important;
		color: #5D5FEF !important;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		text-underline-offset: 3px;
		text-decoration-color: #5D5FEF;
		margin-left: 15px;
		cursor: pointer;
	}

	.MuiDataGrid-root .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal {
		display: block !important;
	}

	.Drpdownselect_pagination {
		font-size: var(--Agent_fontsizeextrasmall);
		height: 45px;
		border-radius: 5px;

		.MuiSelect-select {
			color: var(--primary) !important;
			font-family: var(--fontFamily) !important;
			font-size: var(--Agent_fontsizeextrasmall) !important;
			margin-top: 1px;

		}
	}

	.Paginationlabel_text {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--primary);
		margin-top: 20px;
	}

	.Paginationlabel_MemberSelectedtext {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--primary);
		font-weight: bold;
	}

	.Mainbtncontainer {
		padding-left: 25px;
		padding-right: 25px;
		width: 100%;
	}

	.SaveAsDraft_btn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--btncolor) !important;
		color: var(--secondary) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
	}

	.disable_SaveAsDraft_btn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: #e1e1e1 !important;
		width: 100% !important;
		height: 45px !important;
		border-radius: 5px !important;
		text-transform: none !important;
		/* color: #000000 !important; */
		font-weight: bold !important;
		pointer-events: none;
		cursor: not-allowed;
	}

	.Delete_btn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--secondary) !important;
		color: var(--Agent_textcolor2) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		border: 1px solid var(--Agent_textcolor2);

	}

	.Publish_btn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: var(--btncolor) !important;
		color: var(--secondary) !important;
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
	}

	.disable_Publish_btn {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		background-color: #e1e1e1 !important;
		/* color: #c5d3e1; */
		width: 100% !important;
		border-radius: 5px !important;
		text-transform: none !important;
		font-weight: bold !important;
		height: 45px;
		pointer-events: none;
		cursor: not-allowed;
	}


	.MuiOutlinedInput-notchedOutline {
		border: none ! important;
	}

	.btnvarianttype2 {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: var(--Agent_bgcolor1) !important;
		width: 100% !important;
		height: 45px !important;
		border-radius: 5px !important;
		font-size: 14px;
		text-transform: none !important;
		font-weight: bold !important;

	}

	.btnvarianttype2_disabled {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		width: 100% !important;
		height: 45px !important;
		border-radius: 5px !important;
		font-size: 14px;
		text-transform: none !important;
		font-weight: bold !important;

	}

	.zipcode_inputbox {
		border: 1px solid var(--Agent_outlinecolor);
		height: 45px;
		border-radius: 5px;
		background-color: white;
	}

	.zipcode_inputbox Input {
		margin-top: -5px
	}

	.labelto {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
	}

	.MuiCheckbox-root {
		/* Change the unchecked color */
	}

	.Mui-checked.MuiCheckbox-root {
		color: var(--Agent_bgcolor1) !important;
	}

	.statuscampaignTrackertext {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizeextrasmall);
		color: var(--primary);
	}

	.Invitationreceivedbtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: #F0FFF9 !important;
		color: #46B8A1;
		border: 1px solid #46B8A1;
		width: 100% !important;
		border-radius: 100px !important;
		font-size: 12px;
		text-transform: none !important;
		font-weight: bold !important;
	}

	.Invitelinkfollowedbtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: #FDFFF0 !important;
		color: #F2BD03;
		border: 1px solid #F2BD03;
		width: 100% !important;
		border-radius: 100px !important;
		font-size: 12px;
		text-transform: none !important;
		font-weight: bold !important;
	}

	.Surveycompletebtn {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: #F6FFED !important;
		color: #52C41A;
		border: 1px solid #52C41A;
		width: 100% !important;
		border-radius: 100px !important;
		font-size: 12px;
		text-transform: none !important;
		font-weight: bold !important;
	}

	.LinknotShared {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		background-color: #F6FFED !important;
		color: #c4251a;
		border: 1px solid #c41a1a;
		width: 100% !important;
		border-radius: 100px !important;
		font-size: 12px;
		text-transform: none !important;
		font-weight: bold !important;
	}

	.bargraphgrid {
		display: grid !important;
		place-items: center !important;
	}

	.paddingleftright {
		padding-left: 25px;
		padding-right: 25px;
	}

	.ChartTitle {
		font-family: var(--fontFamily) !important;
		font-size: var(--fontsizesmall);
		color: #5a5a5a;
	}

	.notificationboxdiv {
		width: 30px;
		height: 30px;
		background-color: red;
		border-radius: 100px;
		position: absolute;
		right: -10px;
		top: -15px;
		font-size: 10px;
		display: flex;
		place-items: center;
		justify-content: center;

	}

	.notoficationoverlaydiv {
		width: 500px;
		box-shadow: 0 8px 8px rgb(0 0 0 / 30%);
		position: absolute;
		right: 0;
		top: 150px;
		background-color: #ffffff;
		z-index: 1000;
		border: 1px solid #e9e8e8;
		display: flex;
		align-items: center;
		transition: right 0.3s ease-in-out;

	}

	.notificationoverlaydivimg {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: -5%;
		cursor: pointer;
		z-index: 1001 !important;
		width: 40px;
	}

	.notificationoverlaydiv-content {
		position: relative;
		width: 100%;
		height: 310px;
		overflow-y: auto;
	}

	.notification_tablecontent {
		align-self: flex-start;
		position: absolute;
		top: 0;
		width: 100%;
		text-align: center;
	}

	.notoficationoverlaydiv_close {
		width: 500px;
		/* height: 300px; */
		box-shadow: 0 8px 8px rgb(0 0 0 / 30%);
		position: absolute;
		top: 150px;
		background-color: #ffffff;
		border: 1px solid #e9e8e8;
		display: flex;
		align-items: center;
		right: -600px;
		transition: right 0.3s ease-in-out;
		display: none;

	}

	.label_text_notofication {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--primary);
		margin-bottom: 5px;
		font-weight: bold;
	}

	.label_text1-notofication {
		font-family: var(--fontFamily) !important;
		font-size: var(--Agent_fontsizeextrasmall);
		color: var(--primary);
		/* margin-bottom: 15px; */
		font-weight: bold;
		color: #5D5FEF !important;
		text-decoration: underline;
		text-decoration-skip-ink: none;
		text-underline-offset: 3px;
		text-decoration-color: #5D5FEF;
		white-space: nowrap;
		cursor: pointer;
		overflow: hidden;
        text-overflow: ellipsis;

	}

	.Resolvebtn {
		font-family: var(--fontFamily) !important;
		font-size: 12px;
		background-color: var(--Agent_bgcolor3);
		color: #ffffff;
		width: 80% !important;
		height: 30px;
		border-radius: 5px !important;
		text-transform: none !important;
		margin-top: -10px;
		margin-bottom: 14px;
		/* margin-bottom: 15px; */
	}

	.Resolvedbtn {
		font-family: var(--fontFamily) !important;
		font-size: 15px;
		color: #349e02;
		margin-top: -10px;
		margin-bottom: 14px;
		line-height: 30px;
	}

	.resolve_Submitbtn {
		font-family: var(--fontFamily) !important;
		font-size: 12px;
		background-color: #349e02;
		color: #ffffff;
		height: 30px;
		border-radius: 5px !important;
		text-transform: none !important;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
	}

	.disabledresolve_Submitbtn {
		font-family: var(--fontFamily) !important;
		font-size: 12px;
		background-color: #e1e1e1 !important;
		color: #c5d3e1;
		height: 30px;
		border-radius: 5px !important;
		text-transform: none !important;
		position: absolute;
		right: 15px;
		top: 50%;
		transform: translateY(-50%);
		cursor: pointer;
		pointer-events: none;
		cursor: not-allowed;
	}

	.searchinput_overlaydiv {
		width: 75%;
		height: 35px;
		background-color: white;
		border: 10px;
		border: 1px solid var(--Agent_outlinecolor);
		margin-top: 5px;
		margin-left: 5px;
	}

	.searchinput_overlaydiv input {
		margin-top: -8px;
	}

	.textfield-container_overlaydiv {
		position: relative;
		display: block;
		background-color: var(--Agent_greycolor);
		height: 50px;
	}

	.disabledropdown {
		pointer-events: none !important;

		.MuiSelect-select {
			color: grey !important;
		}
	}

	.enabledropdown {
		pointer-events: auto !important;
	}

}
